// Plan list

section.plan-list {
	margin-left: -30px;
	header {
		padding-left: 1.5em;
		.filters {
			@include section-like;
		}
	}
	.btn-delete {
		margin-top: 0.5em;
	}
	.empty {
		margin-left: 1em;
	}
	footer {
		padding-top: $grid-gutter-width;
		border-top: 1px solid $gray-light-alt;
		text-align: center;
	}
}