
// Basic BS modal overrides

.modal-header {
	background-color: $brand-primary-subtle-bright;
	border-radius: $border-radius-large $border-radius-large 0px 0px;
	
	// Modal close
	.close.icon {
		font-size: 26px;
		float: none;
		position: absolute;
		top: ($modal-title-padding - 5px);
		right: ($modal-title-padding - 2px);
	}
}

.modal-title {
	color: $brand-primary-alt-darker;
}

.modal-body {
	min-height: 100px;
	overflow: auto;


}

.modal-body-loading-overlay {
	background-color: rgba(0, 0, 0, 0.1);
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	.loading-icon-wrapper {
		// Vertical align
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

}

.modal-footer-pagination {
	text-align: center;
	
	.pagination {
		margin-top: 0;
		margin-bottom: 0;
	}
}


// Strong solid header
.modal-solid-header {
	.modal-header {
		color: white;
		background-color: $brand-primary-alt-bright;
		.close.icon {
			color: white;
		}
	}
	.modal-title {
		color: white;
	}
}
.modal-delete-header {
	.modal-header {
		color: white;
		background-color: #f34d4d;
		.close.icon {
			color: white;
		}
	}
	.modal-title {
		color: white;
	}
}
// Modal selector
// general modal styles
.modal-selector {
	.modal-header {
		display: table;
		width: 100%;
		
		// Direct children
		// (except close button)
		.modal-title,
		.modal-header-search {
			text-align: left;
			display:table-cell;
		}
		
		.modal-title {
			color: $brand-primary-alt-darker;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
			padding-right: $grid-gutter-half;
			white-space: nowrap;
			.total-count {
				color: black;
				font-weight: normal;
				&::before {
					content: '-';
					padding: 0 0.5ch;
				}
			}
		}
		
		// Take all available space
		.modal-header-search {
			width: 99%;
			padding-right: 40px;
		}
		
	}
	
	.modal-body {
		.modal-body-description {
			font-weight: bold;
			margin-top: -($modal-inner-padding / 2);
			margin-bottom: ($modal-inner-padding / 2);
		}
	}
}

.adisco-supervisor{
	.modal-body{
		padding-left: 0;
		padding-right: 0;
	}

	.entries-table{
		.header{
			strong{
				color:#314854;
			}
		}
		.entry{
			@include transition();
			cursor:default;
			&:nth-child(even), &:nth-child(odd){
				background-color: #fff;
				border-bottom: 1px solid #E5E5E5;
			}

			&:last-child{
				border-bottom: none;
			}

			&:hover, &:focus{
				background-color: #fbfbfb;
			}
		}

		.col-record{
			a{
				text-transform: uppercase;
				@include transition();

				&:hover, &:focus{
					text-decoration: none;
					color: darken($blue, 20%);
				}
			}
		}
	}
}

@media (max-width: 992px) {
	.modal-dialog .task-env-summary {
		-ms-word-break: break-word;
		word-break: break-word;
		.date {
			width: 13%;
		}
		.client {
			width: 20%;
		}
		.center {
			width: 19%;
		}
		.zone,
		.facility {
			width: 18%;
		}
		.task-tag {
			width: 12%;
		}
	}
}
@media (min-width: 1024px) {
	.modal-dialog {
		width: 800px;
	}
}
@media (min-width: 1200px){
	.modal-dialog {
		width: 900px;
	}
}


#success_modal {
	text-align: center;
	padding: 0 !important; 

	.modal-dialog {
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		width: 350px; 

		.modal-content {
			border-radius: 5px;
			padding-top: 30px;
			padding-bottom: 30px; 

			.modal-footer {
				border: none !important; 
			}
		}
	}
}

.padding_right_0{
	padding-right: 0px;
}

.padding_left_0{
	padding-left: 0px;
}

.padding_right_2{
	padding-right: 2px;
}

.padding_left_2{
	padding-left: 2px;
}

.icon_caret_down{
	position: absolute;
	top: 1.1em;
	right: 2em;
}

