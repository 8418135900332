
.big-box-info {
    .info-title {
        margin-top: 5px;
    }
    .info-value {
        color: #193b7a;
        font-size: 2.8em;
        font-weight: bold;

        &.detail-color {
            color: #00bed4;
        }
    }

}
.small-box-info {
    .info-title {
        font-size: 0.8em;
        margin: 0;
    }
    .info-value {
        color: #193b7a;
        font-size: 1.5em;
        font-weight: bold;
        line-height: 1.5em;
    }
    .info-txt {
        color: #193b7a;
        font-size: 1em;
        line-height:2em;
    }
}

.quality-control-analysis{
    
    .panel-heading{
        
        .panel-title{

        }
    }

    .panel-body{
        padding: 30px 20px 60px;
    }

    &.type-ranking-centers{
        .info-title{
            color: #68707d!important;
            font-size: 18px!important;
            padding-bottom: 14px;
            margin-top:16px;
            margin-bottom: 30px;
            border-bottom: 8px solid #eef2f5;
        }
    }

    .concepts-title{
        display:block;
        margin: 34px 0 10px;
        font-size: 18px;
        font-weight: 500;

        &.type-total{
            color: #00d7e0;
        }

        &.type-materiel{
            color: #268bb0;
        }

        &.type-methode{
            color: #c698d3;
        }

        &.type-produit{
            color: #b37f9b;
        }

    }
}




.concepts-value {
    display:block;
    margin: 34px 0 10px;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    bottom: 17px;
    right: 15px;
    margin: 0;
    padding: 0;
    &.type-total{
        color: #00d7e0;
    }
    &.type-materiel{
        color: #268bb0;
    }
    &.type-methode{
        color: #c698d3;
    }
    &.type-produit{
        color: #b37f9b;
    }
}
