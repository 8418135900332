
@import "variables";
@import "bootstrap/variables";

// Common
@import "cleanpro/variables";
@import "cleanpro/mixins";
@import "cleanpro/common";
@import "cleanpro/utils";

// Components
@import "cleanpro/close";
@import "cleanpro/forms";
@import "cleanpro/buttons";
@import "cleanpro/icons";
@import "cleanpro/navbar";
@import "cleanpro/sidebar";
@import "cleanpro/modals";
@import "cleanpro/pagination";
@import "cleanpro/navs";
@import "cleanpro/tables";

// Cleanpro components
@import "cleanpro/widget-model";
@import "cleanpro/widget-daterange";
@import "cleanpro/widget-monthrange";
@import "cleanpro/modal-selector";
@import "cleanpro/entries-table";
@import "cleanpro/metrics";

// Cleanpro sections
@import "cleanpro/plan-list";
@import "cleanpro/plan-editor";
@import "cleanpro/plan-pdf";
@import "cleanpro/plan-tasks";
@import "cleanpro/tasks";
@import "cleanpro/support-modal";
@import "cleanpro/consumptions";
@import "cleanpro/contact";
@import "cleanpro/quality-control-editor.scss";
@import "cleanpro/_quality-control-list.scss";
@import "cleanpro/_quality-control-record.scss";
@import "cleanpro/_quality-control-analysis.scss";
@import "cleanpro/_quality-control-analysis-fch.scss";
@import "cleanpro/_quality-control-datepicker.scss";



// Layouts
@import "cleanpro/page-modal";
@import "cleanpro/page-app";

// Registration
@import "cleanpro/registration";