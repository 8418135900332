#reportrange {
  float: right;
  margin: 0 1em;
  padding: 0.2em 0.5em;
  position: relative;
  border: 1px solid #a9a9a9;
  input {
    border: 0;
  }
}

.dropdown-menu {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  position: absolute;
  border: 1px solid #D9DEE4;
}
