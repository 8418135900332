// Tasks

@import "bootstrap/mixins";
@import "cleanpro/mixins";
@import "cleanpro/page-app";


// Tasks layout
.tasks {
	// Subheader extends to the edges
	.subheader {
		margin-left: -30px;
		background-color: #fafafa;
		// Subheader defines its own bottom padding, kill last child margin
		& > *:last-child {
			margin-bottom: 0;
		}
	}

	&.subheader-section{
		
		&.type-analysis{

			ul{
				margin-top: 20px;
				margin-bottom: 24px;
				border-color: #ddd;
				margin-left: -16px;
    			padding-left: 16px;
			}

			hr{
				display:none;
			}
		}

	}
}

// Task editor component
task-editor, .task-editor-field {
	// wrap around BS container
	display: block;
	
	// Block
	.task-editor-block {
		margin-bottom: $form-group-margin-bottom;
	}

	// Block body
	.task-editor-block-body {
		border-bottom: $form-block-border-style;
		// Location
		.task-location {
				width: 340px;
			margin-bottom: 15px;
		}
		// Products
		.product-layout {
			@include table-like;
			& > * > * {
				vertical-align: top;
			}
		
			.product-image-wrapper {
				width: 120px;
				padding-bottom: $line-height-computed;
			}
			.product-name-wrapper {
				.product-name {
					display: block;
					border-bottom: 1px solid $table-border-color;
					padding-bottom: $line-height-computed;
					margin-right: -$grid-gutter-half;
				}
		
			}
		
			// No image override
			&.noimage {
				.product-image-wrapper {
					display: none;
				}
				.product-name-wrapper {
					width: 100%;
					.product-name {
						border-bottom: none;
						padding-bottom: 0;
						margin-right: 0;
					}
				}
			}
		
		}
	
	}

	// Task editor block (start date)
	.task-editor-block-start-date {
		input[type=text] {
			margin-left: $grid-gutter-half;
		}
		input[type=text].task-start-date-day,
		input[type=text].task-start-date-month {
			width: 50px;
		}
	
		input[type=text].task-start-date-year {
			width: 80px;
		}
	}
	
	// Task editor block (duration)
	.task-editor-block-estimated-duration {
		input.task-estimated-duration {
			width: 80px;
		}
	}
	
	// Task editor block (controls)
	.task-editor-block-controls {
		border-bottom: none;
		.contents {
			margin-top: $grid-gutter-half;
			margin-bottom: $grid-gutter-width;
			& * {
				vertical-align: baseline;
			}
		
		}
	
	}

	// Task editor status
	.task-editor-status {
		font-weight: bold;
		font-size: 1.2em;
	}
}

// Task creator app
.tasks-create {
	// Each one of the tasks
	task-editor {
		border-bottom: $form-block-border-style;
		margin-bottom: $line-height-computed;
	
		&:last-child {
			border-bottom: none;
		}
	}
}
.task-list-body {
	margin-bottom: 3em;
}
// TaskGenerator list app
.task-list {
	
	.task-list-header {
		.form-inline-block {
			margin-bottom: 0px;
		}
		
		.widget-filters {
			// Desktop
			@media (min-width: $screen-md-min) {
				text-align: right;
			}
		}
		
		.filter-widget {
			display: inline-block;
			vertical-align: middle;
		}
		
	}
	
	// Entry Columns
	.entries-table {
		.client-and-center {
			width: 20%;
			.client {
				@include uppercase;
			}
			
		}
		.task {
			width: 25%;
		}
		.worker {
			width: 15%;
		}
		.frequency {
			width: 10%;
		}
		.date {
			width: 15%;
		}
		.actions,
		.status {
			width: 10%;
			& > * {
				margin-bottom: 5px;
			}
			.btn {
				min-width: 10.2em;
				text-align: left;
			}
		}
	}
	
	.task-list-footer {
		text-align: center;
	}
}

// Task environment definition list
dl.task-env {
	& > div {
		margin-bottom: 5px;
	}
	dt, dd {
		display: inline;
	}
	dt {
		color: $brand-primary-alt-darker;
		font-weight: normal;
		&::after {
			content:": ";
		}
	}
	dd {
		@include uppercase;
	}
}

// Modal task editor (BS Modal)
.modal.modal-task-editor {
	
	.entries-table.task-env-summary {
		margin-top: 0;
		margin-bottom: 18px;
		border-bottom: $form-block-border-style;
		
		.entry {
			background-color: white;
		}
	}
	
}

// Task status labels
.task-status {
	display: inline-block;
	white-space: nowrap;
	text-transform: uppercase;
	color: $brand-primary-alt-darker;
	
	&::before {
		margin-right: 0.8ch;
		font-family: FontAwesome;
		content: "\f10c"; // fa-circle-o
	}
	
	&.task-status-pending {
		&::before {
			color: $brand-primary-alt-bright;
			content: "\f10c"; // fa-circle-o
		}
	}
	&.task-status-success {
		&::before {
			color: $brand-success;
			content: "\f058"; // fa-check-circle
		}
	}
	&.task-status-failed {
		&::before {
			color: $brand-danger;
			content: "\f057"; // fa-times-circle
		}
	}
	&.task-status-issues {
		&::before {
			color: $brand-warning;
			content: "\f06a"; // fa-exclamation-circle
		}
		button {
			margin-left: -5px; // whitespace
			vertical-align: baseline;
			background-color: $brand-warning;
			color: white;
		}
	}
}

// Modal task issue (BS Modal)
.modal.modal-task-issue {
	.modal-header {
		background-color: $brand-warning;
		.close.icon {
			color: white;
		}
	}
	.modal-title {
		color: white;
	}
	
	.entries-table.task-env-summary {
		margin-top: 0;
		margin-bottom: 0;
		.entry {
			background-color: white;
		}
	}
	
	.task-issue-layout {
		@include table-like;
		
		.task-issue-block {
			& > * {
				vertical-align: top;
				padding: 10px 20px;
				&:not(:first-child) {
					padding-left: 0;
				}
			}
			
		}
		
		.task-issue-label {
			width: 1%;
			white-space: nowrap;
			color: $brand-primary-alt-darker;
			text-transform: uppercase;
		}
		
		.task-issue-block-area {
			& > * {
				padding-bottom: 0;
			}
		}
		
		.task-issue-block-area,
		.task-issue-block-frequency {
			& > * {
				background-color: $brand-primary-subtle-dark;
			}
			
		}
		
		.task-issue-image-wrapper {
			img {
				max-height: 400px;
			}
			
			
		}
	}
	
}

.task-metrics {
	.panel-body {
		padding: 1em 3em 3em;
		#incidences-count {
			color: #193b7a;
			font-size: 2.8em;
			font-weight: bold;
		}
		.round.dougnut-red {
			display: inline-block;
			width: 0.8em;
			height: 0.8em;
			border-radius: 1em;
			background: #ff5466;
		}
		h3 {
			margin-top: 0;
		}
	}
}

.status-filter-widget {
	
}
