
// Entries table
// table-like list of entries as rows, usually paginated

.entries-table {
	@include table-like;
	margin: 2em 0;
	
	// Rows
	& > * {
		// Cells
		& > * {
			padding: 20px 20px;
			vertical-align: top;
			color: #5e6166;
			font-size: 13px;
		}
	}
	> * > div.actions,
	> * > div.download {
		vertical-align: middle!important;
	}

	> * > div.actions{
		.btn-edit, .btn-send {
			margin-bottom: 8px;
		}
	}

	.date {
		width: 9em;
	}
	
	// Header
	.header {
		text-transform: uppercase;
		background-color: #e3eef4;

		& > * {
			font-family: $font-family-montserrat;
			font-size: 12px;
			color:#2b708d;
			padding: 0 20px;
			height: 50px;
			vertical-align: middle;
			
			&:not(last-child) {
				border-right: 1px solid white;
			}
			
		}
	}
	
	.entry {
		&:nth-child(even) { background-color: $brand-primary-subtle-light; }
		&:nth-child(odd) { background-color:white; }
		> div:not(last-child) {border-right: 1px solid white;}
	}
}