.quality-control-editor{
    input, textarea{
        &:disabled{
            background: none;
        }
    }

	header{

		& > *{
			margin-left: -30px;
		    padding-left: 30px;
		    margin-right: -15px;
		    padding-right: 15px;
		}

		.migas-cn{
			margin-bottom: 20px;
			border: 0;
		}
		
	}



	h1{
		span{
			color: $blue;
		}
	}

	.cleanpro-nav-tabs{
    	border-bottom: 3px solid $border-grey;
    	margin-bottom: 40px;
	}

	h2{
		color: #585858;
		font-size: 18px;
		font-weight: normal;
		margin-bottom: 18px;

		small{
			font-size: 14px;
			display: block;
			margin-bottom: 30px;
		}
	}

	.box-cn{
		border: 1px dashed $border-grey-blue;
		padding: 20px 22px;
		margin-bottom: 40px;

		h3{
			background-color: #29ccc9;
			color: $white;
			padding: 20px 22px;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 500;
		}
	}

	.save-cn{
		label{
			width: 30%;
			display: inline-block;
			vertical-align: middle;
			font-size: 18px;
			color: #585858;
			text-transform: normal;
			text-transform: none;
    		font-weight: normal;
		}

		input{
			width: 70%;
			display: inline-block;
			margin-right: -5px;
			border-color: $blue;
			font-size: 16px;
			color: rgba($blue, 0.8);
		}
	}

	.submit-cn{
		margin-top: 30px;

		.btn-cancel{
			margin-left: 0;
			float: right;
		}
	}

	.c-r{
		h2{
			color: #bbbbbb;
		}
	}

	.position-cn{
		display:none;
	}

	.input-quality-control{
		border:1px solid #95a6b8;
		background-color: $white;
		padding: 10px;
		@include roundAll(4);
		@include box-shadow(bottom, 15px, rgba($black, 0.2) );
		margin-bottom: 22px;

		&.type-head{
			margin: 16px 0 2px;
			border: none;
			-moz-box-shadow: initial;
		    -webkit-box-shadow: initial;
		    box-shadow: initial;
		    text-transform: uppercase;
		    color: #bbbbbb;
		    font-size: 12px;

		    .input-txt{
		    	color: #bbbbbb;
		    	font-size: 12px;
		    }

		    .right-cn{
		    	padding-right: 30px;
		    }
		}

		&.type-textarea{
			
			.input-txt{
				margin-left: -24px;
				padding-left: 30px;
			}
		}

		.input-txt, .delete-cn, .switch-cn, .drag-cn, .right-cn{
			display:inline-block;
			vertical-align: middle;
			margin-right: -5px;
		}

		.input-txt{
			border: none;
			width: 70%;
			color: #585858;
			font-size: 16px;
			text-transform: uppercase;

			&::-webkit-input-placeholder {color: $blue;opacity: .70;-moz-opacity: .70;filter:alpha(opacity=70);text-transform: none;}
			&:-moz-placeholder {color: $blue;opacity: .70;-moz-opacity: .70;filter:alpha(opacity=70);text-transform: none;}
			&::-moz-placeholder {color: $blue;opacity: .70;-moz-opacity: .70;filter:alpha(opacity=70);text-transform: none;}
			&:-ms-input-placeholder {color: $blue;opacity: .70;-moz-opacity: .70;filter:alpha(opacity=70);text-transform: none;}

			&:focus, &:active{
				outline: none;
			}
		}

		.right-cn{
			min-width: 140px;
			width: 30%;
			text-align: right;
		}

		.delete-cn{
			text-align: center;
			color: #e6eaed;
			font-size: 24px;
			cursor: pointer;
		}

		.switch-cn{
			position: relative;
			text-align: center;
			cursor: pointer;
			margin: 0 16px 0 20px;

			input{
				display:none;
			}

			label{
				position: relative;
				display: inline-block; 
				width: 64px;
				height:34px;
				line-height: 34px;
				margin-top: 2px;
				margin-bottom: 0;
				background-color: #c1c1c1;
				color: #f6f6f6;
				text-align: center;
				text-transform: uppercase;
				font-weight: 400px;
				cursor: pointer;
				@include roundAll(3);
				@include transition();
				// @include box-shadow(bottom, 4px, rgba($black, 0.6) );

				span{
					float:left;
					width: 50%;
				}

				&:after{
					content:'';
					position:absolute;
					top: 2px;
					left: 32px;
					width: 30px;
					height: 30px;
					background-color: $white;
					@include roundAll(3);
					@include transition();
					// @include box-shadow(right, 4px, rgba($black, 0.6) );
				}
			}

			input:checked + label{
				background-color: #82ca7a;

				&:after{
					left: 2px;
				}
			}
		}

		.drag-cn{
			text-align: right;
			color: #e6eaed;
			font-size: 24px;
			cursor: move;

			i:first-child{
				margin-right: -2px;
			}
		}
	}

	.add-cn{
		color: #bbbbbb;
		font-size: 12px;
		margin-bottom:20px;

		i{
			margin-left: 8px;
		}
	}

	.info-small{
		font-size: 11px;
	}

	.icon-textarea{
		position:relative;
		width: 22px;
		height: 2px;
		display: inline-block;
		background-color: #a0a0a0;
		margin-bottom: 12px;
		vertical-align: middle;

		&:before{
			content:'';
			position: absolute;
			top: 5px;
			left: 0;
			width: 80%;
			height: 2px;
			background-color: #a0a0a0;
		}

		&:after{
			content:'';
			position: absolute;
			top: 10px;
			left: 0;
			width: 60%;
			height: 2px;
			background-color: #a0a0a0;
		}
	}

	.concept-value{

		span{
			display: inline-block;
			color: #a6a6a6;
			border: 2px solid #a6a6a6;
			width: 32px;
			height: 32px;
			line-height: 30px;
			text-align: center;
			margin-right:20px;
			@include roundAll100(50%);
			font-size:14px;

			&:last-child{
				margin-right: 0;
			}
		}
	}

	.concept-area{
		margin-top:16px;
		font-size: 14px;
		color: #a0a0a0;
	}

	.form-inline.type-head{
		margin-top: 12px;
		label{
			width: 30%;
			margin-right:0;
		}

		input{
			width: 69%;
			background: #F3F8FB;
			border: none;
		}
	}

	.signature-img{
		display: block;
		border: 1px solid #3ecde2;
    	border-radius: 8px;
    	text-align: center;
    	padding: 20px;
    	height: 274px;
    	overflow: hidden;

		img{
			max-height: 232px;
		}
	}

}