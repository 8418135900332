// Tables

@import "bootstrap/mixins";
@import "bootstrap/tables";
@import "cleanpro/mixins";


.cleanpro-data-table {
	@extend .table;
	
	th, td {
		border-bottom: 1px solid $table-border-color !important;
		border-right: 1px solid $table-border-color;
		&:last-child {
			border-right: none;
		}
	}
	
	th {
		@include uppercase;
		color: $brand-primary-alt-dark;
		background-color: $brand-primary-subtle-light;
	}
}