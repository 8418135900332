// Common styles

@import "cleanpro/mixins";


html, body {
	min-height: 100vh;
}

body {
	font-weight: $font-weight-base;
}

img{
	max-width: 100%;
    height: auto;
}

h1 {
	.extra {
		margin-left: 0.5ch;
		font-size: 18px;
	}
	.highlight {
		color: $brand-primary-alt-bright;
	}
}

section {
	
	header {
		h1 {
			margin-bottom: 1em;
			margin-left: -0.8em;
			padding-left: 0.8em;
			padding-bottom: 1em;
			border-bottom: 1px solid $gray-light-alt;
		}

		.migas-cn{
			margin-bottom: 0px;
			margin-left: -0.8em;
    		padding-left: 0.8em;
		    padding-bottom: 12px;
		    border-bottom: 1px solid #d2d2d2;
		    font-size: 16px;

			a{
				color: #00bed4;
				// font-size: 14px;
				margin-right: 8px;

				&.btn-volver{
					display: inline-block;
					background-color: #F1F6F9;
					color:#33A3F5;
					@include roundAll(4);
					padding: 12px 20px;
					text-decoration: none;
					@include transition();

					&:hover, &:focus{
						background-color: darken(#33A3F5, 25%);
						color: #fff;
					}

				}
			}
		}
	}
}

// App branding
.heading-app {
	font-family: "Open Sans";
	font-size: 16px;
	text-transform: uppercase;
	background-image: url("img/logo_cleanpro.png");
	background-repeat: no-repeat;
	background-position: left center;
	display: table;
	padding-left: 50px;
	min-height: 45px;
	
	.heading-app-content {
		display: inline-block;
		display: table-cell;
		vertical-align: middle;
	}
	
	.heading-app-tagline {
		color: $brand-primary-alt-darker;
		// Never break tagline
		white-space: nowrap;
	}
	
	.heading-app-name {
		color: $brand-primary-alt-bright;
		span {
			font-weight: 600;
			color: #00bdd1;
		}
	}
	
}

.heading-app {
	&.ha-fch {
	    background-image: url("img/logo_adisco.png");
        background-position: top center;
        width: 100%;
        height: 205px;
        padding: 0;
        text-align: center;
	    .heading-app-content {
	        vertical-align: bottom;
	    }
	    .heading-app-name {
            text-align: center;
            font-size: 20px;
            border-bottom: 2px solid #2e5680;
            padding: 5px 15px;
            font-weight: bolder;
            color: #0a89ad;
	    }
	}
}

.heading-app {
	&.ha-inpacs {
	    background-image: url("img/logo_inpacs.png");
        background-position: top center;
        width: 100%;
        height: 205px;
        padding: 0;
        text-align: center;
	    .heading-app-content {
	        vertical-align: bottom;
	    }
	    .heading-app-name {
            text-align: center;
            font-size: 20px;
            border-bottom: 2px solid #2e5680;
            padding: 5px 15px;
            font-weight: bolder;
            color: #0a89ad;
	    }
	}
}


// Section with subheader
.subheader-section {
	.subheader {
		background-color: $brand-primary-subtle-dark;
		padding: $grid-gutter-width;
		color: $brand-primary-alt-darker;
		margin-bottom: $grid-gutter-width;
	}
}

canvas{
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.check-mark {
	color: #00bed4 !important;
}