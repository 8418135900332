
@import "bootstrap/mixins";
@import "cleanpro/mixins";


// Daterange widget
// relies on default bootstrap-daterangepicker css for popup styling

daterange-widget {
	display: inline-block;
	text-align: left;
	width: 220px;
	
	&:hover {
		cursor: pointer;
	};
	
	& > div.form-control {
		&::before {
			@include fa;
			margin-right: 1ch;
			content: "\f073"; // fa-calendar 
		}
	}
}