
// Sidebar
// Shows only in desktop layout
// There are too many shades of colour and they do not match exactly
// what we have in the variables, so we are using exact colour picks
// from the design in several places.


@mixin nav-icon {
	content: ".";
	text-indent: -9999px;
	display: inline-block;
	width: 16px;
	height: 100%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	margin-right: 1ex;
}


// Sidebar
.sidebar {
	position: fixed!important;
	top: 0px;
	z-index: 999;
	nav {
		clear: both;
		overflow: hidden;
		a.logo {
			display: block;
			background: white;
			font-size: 17px;
			line-height: 1.1em;
			padding: 0.2em 0 0.2em 1.8em!important;
			&:hover {
				text-decoration: none;
				background-color: white;
			}
			.heading-app {
				background-size: 1.9em;
				padding-left: 45px;
				.heading-app-name {
					font-size: 12px;
					color: #3d6490;
				}
			}
		}
		.navbar-brand-superbrand {
			position: relative;
			height: 81px;
			top: 0;
		}
	}
}

.scroll-view {
	position: relative;
	overflow-y: auto;
	.fixedMenu {
		padding-top: 1.2em;
		position: fixed;
		width: 250px;
		//#sidebar {
		//	overflow: auto;
		//	position: relative;
		//}
		#sidebar {
			ul {
				li:first-child { 
					border-top: none !important;
				}
			}
		}
	}
}

.consumptions #sidebar {
	overflow: auto;
	position: relative;
}

.consumptions .sidebar {
	ul {
		padding: 0;
		li.nav-root {
			ul li{
				a {
					color: white;
					text-transform: uppercase;
					padding-left: 3.5em;
					background-color: transparent;
				}
				ul li {
					a {
						padding-left: 4.5em;
					}
					ul li {
                      a {
                        padding-left: 5.5em;
                      }
                      ul li {
                        a {
                          padding-left: 6.5em;
                        }
                      }
                    }
				}
			}
		}
	}
	ul li {
		a:hover,
		a.active,
		a.panel-toggle:hover,
		a.panel-toggle.active {
			background-color: $brand-primary-alt-bright!important;
		}
	}
	a {
		border: 0;
		padding: 0.5em;
		text-transform: none;
		//text-indent: 2.5em;
		letter-spacing: 0.05em;
	}
	a.panel-toggle {
		i.fa {
			margin-right: 0.5em;
			font-size: 1.4em;
		}
		i.fa-angle-left {
				display: block;
			}
			i.fa-angle-down {
				display: none;
			}
		&.expanded {
			background-color: $brand-primary-alt-darker;
			i.fa-angle-left {
				display: none;
			}
			i.fa-angle-down {
				display: block;
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.sidebar {
		display: block;
		width: $sidebar-width;
		position: absolute;
		// Navigation
		nav {
			margin-top: 0em;
			font-size: 14px;
			font-weight: normal;
			text-transform: uppercase;
			.nav-planning,
			.nav-analysis,
			.nav-qualitycontrol,
			.nav-clientcentercontrol {
				ul li a {
					text-transform: none;
				}
			}

			h3 {
				color: white;
				font-weight: bold;
				font-size: 1.2em;
				margin: 0 0 0 1.4em;
				padding: 1em 0 1em 0.5em;
				background-color: $brand-primary-alt-darker;
				a {
					color: white;
					&:hover {
						background-color: transparent;
						text-decoration: none;
					}
				}
				i {
					margin-right: 0.4em;
				}
			}
			// Override BS
			ul.nav-stacked li + li {
				margin-top: 0px;
			}
			//a {
			//	text-indent: 1.7em;
			//}
			// Common item styles
			li {
				& > a {
					color: white;
					padding: 0;
					border-left: 0px solid #24729a;
					& > span {
						background-color: #3a5f89;
						padding: 5px 10px 5px;
						position: relative;
						display: block;
					}
					&:hover {
						& > span {
							background-color: #36587f;
						}
					}
					i.fa {
						position: relative;
						z-index: 10;
						color: white;
						font-size: 1.2em;
						line-height: 1.6em;
						margin-right: 0.5em;
					}
				}

				// Nav roots
				&.nav-root > a > span {
					background-color: #375579;
				}

				// Active
				&.active > a {
					border-left-color: #01d4e9;
					margin-bottom: 1px;
					& > span {
						border-top: none;
						border-bottom: none;
						background-color: #0eb6d1;
					}
				}

				// Icons
				&.nav-create > a > span::before {background-image: url('img/nav-icon-create.svg');}
				//&.nav-plan > a > span::before {background-image: url('img/nav-icon-plan.svg');}
				&.nav-planning > a > span::before {background-image: url('img/nav-icon-planning.svg');}
				&.nav-analysis > a > span::before {background-image: url('img/nav-icon-analysis.svg');}
				&.nav-qualitycontrol > a > span::before {background-image: url('img/nav-icon-qualitycontrol.svg');}
				&.nav-clientcentercontrol > a > span::before {background-image: url('img/nav-icon-clients.svg');}

				// hack
				// spacing the last item does not play well with the left border
				&.spacer {
					line-height: 0px;
					height: 10px;
					& > a {
						& > span {
							background-color: $brand-primary-alt-dark;
						}
					}
				}
			}

			// Root
			div > ul.nav > li.nav-root {
				margin-top: 1em;
				padding-top: 0.5em;
				border-top: 1px solid #234163;
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					padding-bottom: 0.5em;
					border-bottom: 1px solid #234163;
				}
			}
			& ul.nav > li {
				& > a {
					& > span {
						background-color: $brand-primary-alt-dark;
						&::before {
							@include nav-icon;
						}
					}
				}

				// Active root
				&.active > a {
					border-left-color: #00d3e9;
				}

				// Second level
				& > ul > li {
					& > a {
						color: white;
						background-color: $brand-primary-alt-dark;
						border-left: 0px solid #24729a;
						& > span {
							padding-left: 1em;
							border-top: 0px solid $brand-primary-alt-dark;
							border-bottom: 0px solid $brand-primary-alt-dark;
							// Second level text alignment
							&::before {
								@include nav-icon;
								visibility: hidden;
							}
						}
					}
					&:not(.nav-plan) a span {
					color: #adb6c7;
					}
					&.active:not(.nav-plan) a span {
						color: white;
						background-color: #199cba;
					}

					// Last item (excep roots)
					//&:not(.nav-root):last-child > a > span {
					//	//background: yellow;
					//	border-bottom: 10px solid $brand-primary-alt-dark;
					//}

					// Specials (roots with icon)
					&.nav-root > a > span::before {
						visibility: visible;
					}

					// not working, switching to hack: dummy item .spacer
					// &.nav-root:last-child > ul > li:last-child > a > span {
					// 	border-bottom: 10px solid $brand-primary-alt-dark;
					// }

					// Third level
					& > ul > li {
						text-transform: none;
						// Menu item
						& > a {
							background-color: $brand-primary-alt-dark;

							& > span {
								color: #adb6c7;
								padding-left: 2em;
								//border-top: 2px solid $brand-primary-alt-dark;
								border-bottom: 0px solid $brand-primary-alt-dark;
							}

						}
						&.active > a span {
							color: white;
							background-color: #199cba;
						}

					}
				}
			}
		}

		// Backdrop
		&::before {
			content: "";
			background-color: $brand-primary-alt-dark;
			position: fixed;
			width: inherit;
			top: 0;
			bottom: 0;
			z-index: -100;
		}
	}
}


