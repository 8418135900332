// Navs

@import "bootstrap/mixins/border-radius";
@import "cleanpro/mixins";


$cleanpro-nav-tabs-border-width: 3px;


// Cleanpro tabs
// (overrides of BS .nav-tabs)
// In the edge of a block
// Expects BS .nav .nav-tabs
.nav-tabs.cleanpro-nav-tabs {
	@include montserrat;
	@include uppercase;
	
	border-bottom: $cleanpro-nav-tabs-border-width solid #eeeeee;
	
	> li {
		float: left;
		// Make the list-items overlay the bottom border
		margin-bottom: -$cleanpro-nav-tabs-border-width;
		
		// Tabs
		> a {
			color: $text-color;
			border-top: none;
			border-left: none;
			border-right: none;
			border-bottom: $cleanpro-nav-tabs-border-width solid transparent;
			border-radius: 0;
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
			
			&:hover,
			&:focus {
				background-color: $gray-lightest;
				border-bottom: $cleanpro-nav-tabs-border-width solid $brand-primary-alt-bright;
			}
		}
	
		// Active tab
		&.active > a {
			&,
			&:hover,
			&:focus {
				color: $brand-primary-alt-bright;
				background-color: inherit;
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: $cleanpro-nav-tabs-border-width solid $brand-primary-alt-bright;
				border-bottom-color: $brand-primary-alt-bright;
			}
		}
		
		// Tabs nav submenu
		// Expects BS's .nav
		// Styled like a BS List group
		.cleanpro-nav-tabs-submenu {
			text-transform: none;
			padding-right: 2px; // from .nav
			position: absolute;
			z-index: 100;
			width: 100%;
			visibility: hidden;
			
			& > li {
				
				// Menu items
				& > a {
					border: 1px solid $list-group-border;
					margin-bottom: -1px;
					background-color: white;
					
					&,
					&:hover,
					&:focus {
						color: $text-color;
					}
					
					&:hover,
					&:focus {
						background-color: $nav-link-hover-bg;
					}
				}
				
				// Active submenu
				&.active > a {
					&,
					&:hover,
					&:focus {
						color: $brand-primary-alt-bright;
					}
				}
				
				&:last-child > a {
					margin-bottom: 0;
					@include border-bottom-radius($border-radius-small);
				}
			} 
		}
		
		// Toggle submenu
		&:hover {
			.cleanpro-nav-tabs-submenu {
				visibility: visible;
			}
		}
		
	}
}