// Navbar

@import "cleanpro/variables";
@import "cleanpro/mixins";


// Superbrand
// Only in desktop layout
.navbar-brand-superbrand {
	display:none;
}
@media (min-width: $screen-md-min) {
	.navbar-brand-superbrand {
		@include logo-prisma;
		display: block;
		position: absolute;
		top: 4em;
		left: 0;
		width: $sidebar-width;
		height: $navbar-height;
		z-index: 100;
		background-size: 180px auto;
		background-position: 1.5em 18px;
		background-color: #26415f;
	}
	body.DICAS{
		.navbar-brand-superbrand {
			@include logo-dicas;
		}
	}
}


// top-brand
.top-brand {
  height: 6.2em;
	border-bottom: 1px solid $gray-light-alt;
  width: 100%;
  top: 0;
  z-index: 4;
  position: fixed;
  background: white;
  .navbar-brand {
    padding: 3px 15px;
    height: 4em;
  }
	.heading-app {
		margin-left: 1em;
		padding-left: 2.4em;
		min-height: 45px;
		background-size: 1.9em;
	}
  .heading-app-name{
	  font-size: 0.785em;
	  color: $brand-primary-alt-dark;
  }
  .navbar-controls-wrapper {
    height: 6.2em;
    margin: 0 2em 0 0;
  	float: right;
  	.btn {
		padding: 0.3em 1.2em;
	}
  }
}


// Main navbar
// TODO: mobile layout needs work
.navbar {
	background-color: #f4f8fb;
	border: none;
	
	// App heading
	// keep folded to give space to the account controls
	.heading-app {
		max-width: 400px;
		// Tweak position of app heading (with logo)
		margin-top: -12px;
	}
	
	// Menu toggle
	.navbar-toggle {
		border-color: $navbar-default-toggle-border-color;
		&:hover,
		&:focus {
			background-color: $navbar-default-toggle-hover-bg;
		}
		.icon-bar {
			background-color: $navbar-default-toggle-icon-bar-bg;
		}
	}
	
	// Desktop layout
	// With superbrand and sidebar
	@media (min-width: $screen-md-min) {
		
		// Main section
		// extra gutter
		.navbar-main {
			padding-left: $navbar-padding-horizontal;
		}
		
		// Hide navbar nav
		.navbar-nav {
			display: none;
		}
	}
	
	// Large
	@media (min-width: $screen-lg-min) {
		// App heading
		// Toggle one-line heading from 1200px and above
		.heading-app {
			max-width: none;
		}
	}
}


// Navbar controls
.navbar-controls-wrapper {
	float: left;
	height: 4em;
	margin-top: 1.2em;
	display: table;
	> * {
		display: table-cell;
		vertical-align: middle;
		&:not(:first-child) {
			padding-left: $grid-gutter-half;
		}
		.btn-primary {
			font-weight: bold;
			background-color: rgba(255, 255, 255, 0.1);
			padding: 0.8em 2.5em;
			&:hover,
			&.active {
				background-color: $brand-primary-hover;
			}
		}
	}
}

.horizontalMenu {
	border-top: 1px solid $gray-light-alt!important;
	margin-left: $sidebar-width;
	background-color: $gray-lightest;
	ul {
		margin-left: 0;
		margin-top: 3.1em;
		padding-left: 2em;
		border-bottom-width: 3px;
	}
	li {
		margin-bottom: -3px;
		&:hover {
			cursor: pointer;
			background: transparent;
			border-bottom: 4px solid $brand-primary-hover !important;
		}
		a {
			border: none;
			color: $gray-light;
			font-weight: bold;
			text-transform: uppercase;
			&:hover {
				color: $gray-dark;
				background: transparent;
			}
		}
	}
}

.page-app:not(.consumptions) {
	.tab-plans {
		border-bottom: 4px solid $brand-primary-hover!important;
		a {
			color: $gray-dark;
		}
	}
}
.consumptions {
	.tab-consumptions {
		border-bottom: 4px solid $brand-primary-hover!important;
	}
}

// Navbar logout button
.btn-account-logout {
	font-size: 13px;
    color: #476379;
	background-color: #e6eaf3;
	text-transform: none;
	padding: 10px 15px;
	
	&:hover,
	&:focus {
		background-color: $gray-lightest;
	}
	
	&::after {
		color: $brand-primary;
		margin-left: 0.8ch;
		font-family: FontAwesome;
		content: "\f011"; // fa-power-off
	}
}