// Plan Editor

@import "bootstrap/mixins";
@import "cleanpro/mixins";
@import "cleanpro/page-app";


// Plan editor main layout
section.plan-editor {
	margin-bottom: $grid-gutter-width;
	
	// Section header
	& > header {
		margin-bottom: $grid-gutter-half;
	}
	
	// Editor fields and sections
	// Extends to the edge
	.plan-editor-body {
		@include page-app-main-edges;
		padding-bottom: $grid-gutter-half;
		margin-bottom: $grid-gutter-half;
	}
	
	// Data header (client, center, env)
	.plan-editor-header {
		@include page-app-main-edges;
		background: white;
		
		.row {
			padding-top: $grid-gutter-half;
			padding-bottom: $grid-gutter-half;
			
			// Client-Center row background
			&:first-child {
				background-color: $brand-primary-subtle-dark;
			}
		}
		.row-small{
			padding-top: 0;
		}
	}
	
	// Area block
	section.area {
		margin-bottom: $grid-gutter-half;
		padding-left: $grid-gutter-half;
		padding-right: $grid-gutter-half;
		border: 1px solid #ccc;
		padding-bottom: 50px;
		margin-bottom: 50px;
		
		& > header {
			padding-top: $grid-gutter-half;
			background-color: $brand-primary-subtle-dark;
		}
	}
	
	// Bottom controls
	footer.controls {
		text-align: center;
		.btn {
			height: 50px;
		}
		.btn-cancel {
			line-height: 1.8em;
		}
	}
	
}


// Product configuration
section.product-conf {
	position: relative;
	background-color: white;
	
	margin-left: -$grid-gutter-half;
	margin-right: -$grid-gutter-half;
	margin-bottom: 10px;
	
	padding-left: 120px + $grid-gutter-width;
	padding-top: $grid-gutter-width;
	padding-right: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
	
	border-bottom: 2px solid $brand-primary-subtle-dark;
	
	header {
		@include clearfix;
		border-bottom: 4px solid $brand-primary-subtle-dark;
		margin-bottom: $grid-gutter-width;
		
		.product-name {
			font-size: 16px;
			font-weight: bold;
			color: black;
			float: left;
		}
		
		.product-image {
			width: 120px;
			position: absolute;
			left: 0;
			
			.extras {
				margin-top: $grid-gutter-half;
				padding-left: $grid-gutter-half;
			}
		}
		
		// Header controls
		.controls {
			margin-top: 12px;
			float: right;
		}
	}
	
	// Configuration block/setting
	.product-conf-block {
		margin-bottom: $grid-gutter-width;
		.heading {
			@include form-label-like;
		}
		
		// Block: Dilution
		&.dilution {
			input.dilution-amount {
				width: 80px;
			}
			.dilution {
				margin-right: 10px;
			}
			.form-control {
				display: inline;
				width: auto;
			}
		}
		
		// Block: Frequency
		&.frequency {
			select {
				font-size: 1.1em;
			}
			i.select-icon {
				bottom: 2.2em;
			}
		}
		
		// Dilution extras
		.dilution-help {
			display: inline-block;
			vertical-align: middle;
			font-size: 0.8em;
			margin-right: 10px;
			
			.dilution-unit {
				display: block;
			}
		}
		
	}
	
}


// Plan Items widget
// shows the state of a modal-selectable multi-item model
//plan-editor-items-widget {
	
	// Items Layout
	// Individual items as a table inside a layout table
	// with a single, vertically centered control item to the right
	// For the border rounding and the background to work,
	// it needs to be applied to the layout-contained cells.
	.items-layout {
		display: table;
		border-collapse:separate;
		width: 100%;
		
		& > * {
			display: table-cell;
		}
		
		.items-layout-control {
			position: relative;
			width: 50px;
			
			& > .wrapper {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				
				.items-control {
					display: table;
					position: absolute;
					top: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					text-align:center;
					background: $brand-primary-subtle-light;
					padding: 5px 10px 5px;
					border: 1px solid #c6cad3;
					border-left: none;
					border-radius: 0px $border-radius-base $border-radius-base 0px;
					
					& > .wrapper {
						display: table-cell;
						vertical-align: middle;
					}
					
					.icon {
						&::before {
							color: $brand-primary;
						}
					}
				}
			}
			
		}
	}
	
	// Actual items container
	.items {
		border: 1px solid #c6cad3;
		border-radius: $border-radius-base 0px 0px $border-radius-base;
		
		// Item
		.item {
			display: table;
			width: 100%;
			
			&[draggable] {
				@include unselectable;
				cursor: move;
			}
			
			&:not(:last-child) {
				border-bottom: 1px solid #c6cad3;
			}
			
			& > * {
				display: table-cell;
				padding-top: 6px;
				padding-right: 0px;
				padding-bottom: 6px;
				padding-left: 10px;
				vertical-align: middle;
				height: 70px;
				&:first-child { padding-left: 15px; }
				&:last-child { padding-right: 10px; }
				
			}
			
			// Column: Step number
			.step-wrapper {
				width: 40px;
				.step {
					color: white;
					font-size: 18;
					font-weight: bold;
					background-color: $brand-primary;
					border-radius: 50%;
					text-align: center;
					line-height: 30px;
					display: inline-block;
					vertical-align: baseline;
					width: 30px;
					height: 30px;
				}
			}
			
			// Column: Thumbnail
			.thumb {
				width: 70px;
				img {
					max-height: 60px;
					margin: 0 auto;
				}
				
			}
			
			// Column: Controls
			// Full to the right
			.controls {
				white-space: nowrap;
				width: 1px;
				
				.icon + .icon {
					margin-left: 0.5ch;
				}
				
				.icon::before {
					color: $gray-light-alt;
				}
			}
			
			// Placeholder item
			// Empty item used only when the list is empty
			// (not used for Equipment, which is a special case)
			&.placeholder {
				position: relative;
				color: $gray-light;
				
				// Hide unused columns
				.thumb,
				.step-wrapper {
					display: none;
				}
				.description {
					padding-left: 15px;
				}
				
				// Backdrop with a pseudoelement
				// this plays nice with the rounded corners
				::after {
					border-radius: $border-radius-base 0px 0px $border-radius-base;
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: -1;
					background-color: $gray-lightest;
				}
			}
			
			// Sorting states
			&.sortable-ghost {
				opacity: 0.2;
				& > * {
					background-color: $gray-light-alt;
				}
			}
			&.sortable-drag {
				background-color: white;
			}
		}
	}
	
//}





// Plan Task Layout


section.task {
	max-width: 95%;
    margin: 50px auto 0;
}
.header-task {
	padding: 10px;
    background: #36aeea;
    margin-top: 50px;
    padding-right: 20px;
}
.task-header-title {    
	display: inline-block;
    margin: 5px 0;
    color: #FFF;
    font-size: 22px;
}

.body-task {
	padding: 15px 0;
}

.task-img {
	img {
		border: 4px solid #36aeea;
		border-radius: 8px;
	}
}


.product-image {
	img {
		width: 150px;
	}
}

.add-btt-compl {
    width: 100%;
    height: 130px;
    background: #f3f7f9;
    background: #f3f7f9;
    padding: 5px 10px 5px;
    border: 1px solid #c6cad3;
    box-shadow: none;
    color: #36aeea;
    margin-bottom: 30px;
    //font-size: 22px;
    font-size: 15px;
    .icon {
    	font-size: 60px;
    }
}

.compl-head {
	margin-bottom:10px;
}
.product-conf-block {
	.heading {
		color: #00548a;
		font-size: 19px;
		text-transform: uppercase;
		margin: 50px 0 15px;
		&.col-light {
			color: #75a5cb;
			font-size: 18px;
		}
	}
}


.compl-div {
	position: relative;
    background-color: white;
    margin: 0;
    margin-bottom: 20px;
    padding-bottom: 50px;
    border: none;
    border-bottom: 2px solid #e5e9f2;
    width: 100%;
}

.label-area{
	padding-top: 0.8em;
}
    