// Buttons

@import "bootstrap/mixins/buttons";


.btn {
	text-transform: uppercase;
	border-radius: 5px;
	&:not(.btn-default) {
		border: none;
	}
}

.btn-xs {
	font-size: 11px;
	padding: 2px 6px;
	line-height: 1.6;
}

.btn-primary {
	&:hover,
	&:focus {
		background-color: $brand-primary-hover;
	}
	&:active {
		background-color: $brand-primary-active;
	}
}

.btn-disabled {
	background-color: #999999;
	cursor: inherit;
	color: #fff;
	opacity: 0.3;
	&:hover,
	&:focus {
		background-color: #999999;
		color: #fff;
	}
	&:active {
		background-color: #999999;
		color: #fff;
	}
}

body.DICAS{
	.btn-primary {
		background-color: #324061;
		&:hover,
		&:focus {
			background-color: #7d9dcf;
		}
		&:active {
			background-color: #7d9dcf;
		}
	}
}

.btn-wide {
	padding-left: 80px;
	padding-right: 80px;
}


// Custom buttons with icon inside

.btn-see,
.btn-edit,
.btn-delete,
.btn-delete-grey,
.btn-pdf,
.btn-replicate,
.btn-send {
	&::before {
		margin-right: 0.8ch;
		font-family: FontAwesome;
		content: "\f0a9"; // fa-arrow-circle-right
	}
	
	// Adjust padding
	&.btn-xs {
		padding-right: 7px;
	}
}

.btn-replicate {
	@include button-variant(white, $brand-primary-alt-bright, transparent);
	&::before { content: "\f24d"; } // fa-clone
}

.btn-send {
	@include button-variant(white, $brand-primary-alt-bright, transparent);
	&::before { content: "\f0e0"; } // fa-envelope
}

.btn-see {
	@include button-variant(white, $brand-warning, transparent);
	&::before { content: "\f06e"; } // fa-eye
}

.btn-edit {
	@include button-variant(white, $brand-primary-alt-bright, transparent);
	&::before { content: "\f040"; } // fa-pencil
}

.btn-delete {
	@include button-variant(white, $brand-danger, transparent);
	&::before { content: "\f1f8"; } // fa-trash
}

.btn-delete-grey {
	&::before { content: "\f1f8"; } // fa-trash
}

.btn-delete-area {
  margin-right: 15px;
  float: right;
}

.btn-pdf {
	&::before { content: "\f1c1"; } // fa-file-pdf
}

.btn.btn-cancel {
	margin-left: 2em;
	border: 1px solid #a0a0a0;
	color: #bbbbbb;
	box-sizing: border-box;
	&:hover {
		background-color: $gray-lighter;
	}
}

.btn-support {
	color: #00c5c9;
	background-color: #e6eaf3;
	text-transform: none;
	font-size: 13px;
    color: #476379;
	&:hover,
	&:focus {
		background-color: $gray-lightest;
	}
	&::after {
		font-family: FontAwesome;
		color: $brand-primary;
		margin-left: 0.8ch;
		content: "\f29c"; } // fa-question-circle-o
}
