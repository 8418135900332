.quality-control-record{
	padding: 40px;
	background-color: #f5f8fa;
	margin-bottom: 40px;

	.c-l{
		color: #1d1d26;
		
		h3{
			font-size:24px;
			margin-bottom: 40px;
		}

		p{
			font-size:15px;
			margin-bottom: 40px;

			span{
				display: block;
				padding-left: 22px;
			}
		}

		.status-cn{
			position:relative;
			color: #f3a55f; 
			font-size: 15px;
			padding-left: 22px;

			&:before{
				content:'';
				position: absolute;
				top: 2px;
				left:0;
				width: 16px;
				height: 16px;
				background-color: #f3a55f; 
				@include roundAll100(50%);
			}
		}
	}

	.acta-cn{
		max-width: 600px;
		margin:0 auto;
	}

	.acta-head{
		// background-image: url("img/logo_prisma.png");
		@include bgImg('acta-head-bg.png');
		padding:80px 60px 14px;

		h3{
			color: $white;
			font-size:24px;
			margin-bottom: 4px;
			font-weight: normal;
		}

		p{
			color: #1d1d26;
			font-size: 15px;
			margin-bottom: 0;
		}
	}

	.acta-main{
		background-color: $white;
		padding: 40px 60px;
	}

	.acta-details{

		p{
			margin-bottom: 34px;
		}

		.name-cn{
			color: #a8a8aa;
			font-size: 12px;
			display:block;
			margin-bottom: 14px;
			text-transform: uppercase;
		}

		.value-cn{
			display:block;
			font-size: 14px;
			color: #090909;
			padding-bottom: 14px;
			border-bottom: 1px solid #e8e8e9;
		}
	}

	.title-green{
		background-color: #29ccc9;
		color: $white;
		padding: 20px 22px;
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 0;
	}

	.acta-concepts{

		.box-grey{
			padding: 20px 22px;
			background-color: #f8f8f8;
		}

		.name-cn{
			color: #3d6490;
			font-size: 15px;
			text-transform: uppercase;
			display: block;
			margin-bottom: 18px;
		}

	}

	.icon-textarea{
		position:relative;
		width: 22px;
		height: 2px;
		display: inline-block;
		background-color: #a0a0a0;
		margin-bottom: 12px;
		vertical-align: middle;
		margin-right: 12px;

		&:before{
			content:'';
			position: absolute;
			top: 5px;
			left: 0;
			width: 80%;
			height: 2px;
			background-color: #a0a0a0;
		}

		&:after{
			content:'';
			position: absolute;
			top: 10px;
			left: 0;
			width: 60%;
			height: 2px;
			background-color: #a0a0a0;
		}
	}

	.concept-value{

		span{
			display: inline-block;
			color: #a6a6a6;
			border: 2px solid #a6a6a6;
			width: 32px;
			height: 32px;
			line-height: 30px;
			text-align: center;
			margin-right:20px;
			@include roundAll100(50%);
			font-size:14px;
			background-color: $white;

			&.current{
				background-color: $blue;
				border-color: $blue;
				color: $white;
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}

	.concept-area{
		padding: 0px 22px;
		padding-bottom: 14px;
		border-bottom: 1px solid #e8e8e9;
		margin-top: 20px;
		margin-bottom: 24px;

		h6{
			margin-bottom: 16px;
			color: #a8a8aa;
			font-size: 15px;

		}

		p{
			margin-left: 37px;
			font-size: 13px;
			color: $black;
		}
	}

	.conformities-area{
		padding: 30px 0px 20px 22px;

		strong{
			color:#3d6490;
			font-size: 12px;
		}

		.img-cn{
			border:1px solid #29ccc9;
			padding:10px;
			text-align: center;
		}
	}
}

.records-actions-pdf{
		background-image: url("img/records-actions/pdf.png");
		padding-left: 23px;
		background-repeat: no-repeat;
		color: #377290;
		text-transform: uppercase;

		&:hover
		{
			background-image: url("img/records-actions/pdf_on.png");
			color: #377290;
			text-decoration: none;
		}
}

.records-actions-delete{
		background-image: url("img/records-actions/delete.png");
		padding-left: 23px;
		background-repeat: no-repeat;
		color: #377290;
		cursor: pointer;
		text-transform: uppercase;

		&:hover
		{
			background-image: url("img/records-actions/delete_on.png");
			color: #377290;
			text-decoration: none;
		}
}

.records-actions-view{
		background-image: url("img/records-actions/view.png");
		padding-left: 23px;
		background-repeat: no-repeat;
		color: #377290;
		text-transform: uppercase;

		&:hover
		{
			background-image: url("img/records-actions/view_on.png");
			color: #377290;
			text-decoration: none;
		}
}

.padding_records{
	display: block;
	padding-bottom: 5px;
}