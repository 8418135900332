.ui-widget-header {
    color: #2f78b2 !important;
}
.ui-widget-content {
    color: #2f78b2 !important;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    color: #2c7096 !important;
}

.ui-widget-header .ui-icon {
    background-image: url(http://code.jquery.com/ui/1.12.1/themes/base/images/ui-icons_ffffff_256x240.png) !important;
}