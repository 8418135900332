@import "cleanpro/variables";
@import "cleanpro/mixins";

.client-row {
  margin-left: -30px;
  padding: 2em 1em;
  background-color: $brand-primary-subtle-dark;
}

.graph-options {
  li {

    &.active {
      border: none;
      a,
      a:hover {
        color: $brand-primary-alt-bright;
        background-color: transparent;
        border: none;
        border-bottom: 3px solid $brand-primary-alt-bright;
      }
    }
    a {
      cursor: pointer;
      color: $gray-light;
      border: none;
      font-weight: bold;
      &:hover {
        border: none;
        background-color: transparent;
        border-bottom: 3px solid $brand-primary-alt-bright;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

#category_select {
  margin: 0 1em 0 0.5em;
}

.consumptions {
  #reportrange,
  #category_select  {
    cursor: pointer;
    width: auto;
    display: inline-block;
    input {
      cursor: pointer;
    }
  }
  #reportrange i {
    margin-top: 0.6em;
  }
  .amount-consumption {
    font-size: 2em;
  }
  .legend {
    ul {
      list-style: none;
      li {
        margin-top: 1em;
      }
      .percentage {
        text-align: right;
        display: inline-block;
        width: 7em;
      }
      .color {
        width: 1em;
        height: 1em;
        margin: 0 1em;
        display: inline-block;
        border-radius: 0.2em;
      }
    }
  }
  #CategoryMenu {
    margin-right: 1em;
    i {
      font-size: 1.2em;
      margin: 0 0.5em;
      color: $brand-primary-alt-bright;
    }
    .dropdown-menu {
      display: none;
      width: 100%;
      margin: 0;
      padding: 0;
      li {
        padding: 0.5em;
      }
    }
    .open .dropdown-menu{
      display: block;
      li.active {
        background-color: $brand-primary-subtle-bright;
      }
    }
  }
}

.dashboard_graph {
  border: 1px solid $gray-light-alt;
  padding: 0 1em 1em;
  margin: 2em 1em 2em 0;
  .x_title {
    padding-left: 1em;
    h1 {
      font-size: 1.5em;
      font-weight: bold;
      margin: 0.6em 1em 0.3em 0em;
    }
  }
}

.top-consumptions{
  thead {
    background-color: $brand-primary-subtle-dark;
  }
  tbody tr:nth-child(even) {
    background-color: $brand-primary-subtle-dark;
  }
}

#selected_options {
  margin: 1.5em 0;
  span {
    margin-right: 0.5em;
  }
}

.color-all-count {
  color: #0f3f7d;
}
.color-count {
  color: #8297f4;
}
