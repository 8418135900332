
// Support Modal
.support-modal {
	
	form {
		margin-top: 1em;
		margin-bottom: 1em;
	}
	
	// Form overrides
	label,
	.form-label {
		// Labels are not uppercase in support modal
		text-transform: none;
		
		// No break
		// white-space: nowrap;
	}
	.form-horizontal .control-label {
		text-align: left;
	}
	
	// Tweak modal dialog size
	@media (min-width: 768px) {
		.modal-dialog {
			width: 720px;
		}
	}
	
}