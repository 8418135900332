
model-widget {
	display: block;
	background: white;
	border: 1px solid #c6cad3;
	border-radius: $border-radius-small + 1;
	padding: 10px 12px 9px;
	
	.widget-layout {
		@include table-like;
		&:hover {
			cursor: pointer;
		}
	}
	
	.widget-body {
		width: 100%;
		
		.placeholder {
			color: $gray-light-alt;
		}
	}
	
	&:hover {
		cursor: pointer;
	}
	
	// Inline mode
	&.inline {
		display: inline-block;
		.widget-layout,
		.widget-body {
			width: auto;
		}
	}
	
	// Invalid
	&.invalid {
		@include invalid-field;
	}
	
	.widget-controls {
		width: 1px;
		vertical-align: middle;
		white-space: nowrap;
		padding-left: 6px;
	}
	
	.widget-icon {
		@include fa;
		font-size: 18px;
		
		& + .widget-icon {
			margin-left: 2px;
		}
		
		&.widget-icon-clear {
			&::before {
				color: $gray-light-alt;
				content: "\f057"; // fa-times-circle
			}
		}
		
		&.widget-icon-select {
			&::before {
				color: $brand-primary;
				content: "\f002"; // fa-search
			}
		}
	}
	
	// Disabled widget
	&.disabled {
		color: $gray-light;
		
		// No clicking
		&:hover {
			cursor: not-allowed;
		}
		
		.widget-icon {
			// Hide clear icon
			&.widget-icon-clear {
				display: none;
			}
			// Gray select icon
			&.widget-icon-select::before {
				color: $gray-light-alt !important;
			}
		}
		
	}
}
