

// Modal selector
// general modal styles
.modal-selector {
	.modal-header {
		display: table;
		width: 100%;
		
		// Direct children
		// (except close button)
		.modal-title,
		.modal-header-search {
			text-align: left;
			display:table-cell;
		}
		
		.modal-title {
			color: $brand-primary-alt-darker;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
			padding-right: $grid-gutter-half;
			white-space: nowrap;
			.total-count {
				color: black;
				font-weight: normal;
				&::before {
					content: '-';
					padding: 0 0.5ch;
				}
			}
		}
		
		// Take all available space
		.modal-header-search {
			width: 99%;
			padding-right: 40px;
		}
		
	}
	
	.modal-body {
		.modal-body-description {
			font-weight: bold;
			margin-top: -($modal-inner-padding / 2);
			margin-bottom: ($modal-inner-padding / 2);
		}
	}
}


// Item choices
// By default single selection, may be multiple
.item-choices {
	.items {
		
		// Header and items table-like
		.header,
		.item {
			display: table;
			table-layout: fixed;
			width: 100%;
			// Columns
			& > * {
				display: table-cell;
				padding-top: 4px;
				padding-right: 0px;
				padding-bottom: 4px;
				padding-left: 20px;
				vertical-align: middle;
				&:first-child { padding-left: 15px; }
				&:last-child { padding-right: 10px; }
			}
		}
		
		// Header defaults
		.header {
			font-weight: bold;
		}
		
		// Item defaults
		.item {
			border: 1px solid #c6cad3;
			border-radius: $border-radius-base;
			margin-bottom: 5px;
			min-height: 40px;
			padding-top: $padding-base-vertical;
			padding-bottom: $padding-base-vertical;
			
			&:hover {
				background-color: $brand-primary-subtle-light;
				cursor: pointer;
			}
			
			// Default columns
			.item-id {
				width: 60px;
			}
			.item-status {
				width: 50px;
				text-align: right;
			}
			.item-thumb {
				width: 100px;
				img {
					max-height: 70px;
					margin: 0 auto;
				}
			}
			
			// Status (icon)
			.icon-status {
				&::before {
					color: $gray-lighter;
					content: "\f058"; // fa-check-circle
				}
			}
			
			// Item is selected
			&.selected {
				box-shadow: inset 0 0 5px $brand-success;
				.icon-status {
					&::before {
						color: $brand-success;
					}
					
				}
			}
		}
		
		// Resource type overrides
		// Client
		&.items-client {
			.item-id { width: 80px; }
		}
		// Center
		&.items-center {
			.item-id { width: 120px; }
			.item-name { width: 200px; }
		}
		// Plan
		&.items-plan {
			.item {
				font-size: 14px;
			}
			.item-date { width: 90px; }
			.item-client { width: 200px }
			.item-center { width: 140px; }
			.item-facility { width: 100px; }
			.item-zone { width: 100 px; }
			
		}
	}
}