// App page
// with sidebar

@import "cleanpro/variables";

.page-app {
	.horizontalMenu {
		position: fixed;
		top: 4em;
		z-index: 4;
		width: 100%;
	}
	div.body {
		padding-top: 11em;
		z-index: 1;
	}
	&.noHorizontalMenu div.body {
		padding-top: 4em;
	}
}


@mixin page-app-main-edges {
	// Extends element background up to the edges
	// (reverts extra page-app-main gutter + regular gutter)
	margin-left: (-2 * $grid-gutter-half);
	padding-left: (2 * $grid-gutter-half);
	margin-right: (-1 * $grid-gutter-half);
	padding-right: (1 * $grid-gutter-half);
	
	// Adjust rows
	& > .row {
		margin-left: (-2 * $grid-gutter-half);
		padding-left: (1 * $grid-gutter-half);
	}
}


// Main content
@media (min-width: $screen-md-min) {
	// Grid Container
	.page-app-main {
		min-height: 78vh;
		margin-left: $sidebar-width;
		// Extra padding
		padding-left: (2 * $grid-gutter-half);
		
		// Grid column that holds all the main content
		.page-app-main-body {
			// Main section extra top spacing
			& > section {
				padding-top: 34px;
			}
		}
	}
	.noHorizontalMenu .page-app-main {
		min-height: 81vh;
	}
	.footer {
		border-top: 1px solid #d1d2d4;
		margin-left: $sidebar-width;
		// Extra padding
		padding: $grid-gutter-half $grid-gutter-width;
	}
}
