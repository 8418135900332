// checklist

@import "cleanpro/mixins";

.checklist {
    &.subheader {
        margin-left: -30px;
        padding-left: 30px;
        margin-right: -15px;
        padding-right: 15px;
        .subheader-title {
            margin-left: -30px;
            padding-left: 30px;
            margin-right: -15px;
            padding-right: 15px;
            background-color: $brand-primary-subtle-dark !important;
            padding-top: 18px;
            padding-bottom: 18px;
            color: $brand-primary-alt-darker;
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
        }
    }
}

.zone-block {
    .zone-sub-block {
        margin: 10px 0 10px;
        & + .zone-sub-block {
            border-top: 1px solid #d0d3d5;
            padding-top: 10px;
        }
    }
    &.total-block  {
        .zone-sub-block {
            & + .zone-sub-block {
                border: none;
             }
        }
    }
}
.zone-title {
    margin-left: -30px;
    padding-left: 45px;
    margin-right: -15px;
    padding-right: 15px;
    background-color: $brand-primary-subtle-dark !important;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #555555;
    font-size: 20px;
}
.zone-subtitle {
    color: $brand-primary-alt-darker;
    font-size: 18px;
    font-weight: bold;
}

.zone-content {
    .zone-concept {
        border-right: 1px solid #e2edf3;
        padding-left: 30px;
        padding-right: 20px;

        &:first-child{
            padding-left: 15px;
        }
        &:last-child {
            border: none
        }
    }
    .zone-concept-title {
        color: #555555;
        font-size: 18px;
        margin-bottom: 20px;
        text-align:left;
    }
    &.bsz-data {
        .zone-concept-title {
            font-size: 16px;
        }
    }
    .score-concept-list {
        margin: 0;
        padding: 0;
        list-style: none;
        .score-concept-item {
            
            display: flex;
            justify-content: left;
            align-items: center;
            margin-bottom: 20px;
            flex-direction: row;

            .score-value {
                border-radius: 50%;
                display: inline-block;
                width: 40px;
                min-width: 40px;
                height: 40px;
                border: 2px solid #3ecde2;
                background-color: $brand-primary-subtle-dark !important;
                color: #343d49;
                font-size: 20px;
                line-height: 36px;
                text-align: center;
                font-weight: normal;
                margin-right: 12px;
                font-size: 16px;
            }
            .selected {
                background-color: #3ecde2 !important;
            }
            .score-description {
                color: #343d49;
                font-size: 14px;
            }

            textarea{
                width: 100%;
            }
        }
    }

    textarea {
        border: 1px solid #3ecde2;
        border-radius: 8px;
        resize: none;
        padding:10px;
        color: #343d49;
        font-size:14px;
    }
    input {
        border: 1px solid #3ecde2;
        border-radius: 8px;
        resize: none;
        padding:10px;
        color: #343d49;
        font-size:14px;
    }

    .zone-total {
        text-align: center;

        .zone-total-title {
            color: #365579;
            font-size: 18px;
            font-weight: bold;
            margin-top: 30px;
            margin-bottom: 18px;
        }
        .total-value {
            margin-bottom: 15px;
            border-radius: 50%;
            display: inline-block;
            width: 96px;
            height: 96px;
            border: 3px solid #3ecde2;
            background-color: $brand-primary-subtle-dark !important;
            color: $brand-primary-alt-darker;
            font-size: 18px;
            line-height: 86px;
            margin: 0 auto;
            text-align: center;
            font-weight: normal;
        }
        .selected {
            background-color: #3ecde2 !important;
            span {
                font-size: 24px;
            }
        }
    }
}

.zone-form {
    margin: 15px 0;

    .form-label{
        margin-right: 10px;
    }
}

.text-zone-box{
    position: relative;
    display: inline-block;

        .text-zone-label {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 0;
            text-indent: -200%;
            color: transparent;
        }
}
.text-zone {
	display: block;
	background: white;
	border: 1px solid #3ecde2;
	border-radius: $border-radius-small + 1;
	padding: 10px 12px 9px;
	width: 100%;
}


/*---- ---*/

.base-zone {
    margin: 0 0 50px 0;
}

.zone-name {
    margin: 15px 0 0 0;
    text-align: right;
}
.sub-zone-name {
    text-align: right;
    margin: 15px 0 0 0;
}

.bsz-data {
    canvas{
        background: #e6e6e6;
    }
}

.sub-zone-name-content {
    display: table;
    height: 70px;
    .sub-zone-name {
        display: table-cell;
        vertical-align: middle;
        margin: 20px 0 8px 0;
    }
}

.total-data {
    display: table;
    height: 70px;
    .sub-zone-total {
        display: table-cell;
        vertical-align: middle;
        margin: 20px 0 8px 0;
        text-align: right;
    }
}

.zone-total-comments{
    margin: 40px 0 60px;
    textarea {
        min-height: 360px;
        border: 1px solid #3ecde2;
        resize: none;
    }
}

.zone-total-conformity {
    margin: 40px 0 60px;
    input{
        border: 1px solid #3ecde2;
    }
    p{
        margin-bottom: 20px;
    }
}

.type-concepts-ranking-fch{

    .bsz-data canvas{
        background: transparent;
    }
    
    .zone-name.zone-subtitle{
        margin:0 0 20px;
        font-size: 16px;
        font-weight: 500;
    }

    .sub-zone-name.zone-concept-title{
        color: #2e3d50;
        font-size: 14px;
        text-align: right;
    }

    .sub-zone-total.zone-subtitle{
        color: #2e3d50;
        font-size: 16px;
        text-align: right; 
        font-weight: 500;
    }

    .grah-01{
        padding-left: 2px;
        padding-right: 2px;
    }

    .type-table-head{
        display: none;

        .zone-concept-title{
            color: #2e3d50;
            font-size: 14px;
            margin-bottom: 10px;
        }
        
    }

    div[data-position="0"]{
        .type-table-head{
            display: block;
        }
    }

    .type-table-data{
        margin-bottom: 0px;
    }

    .holder-base-canvas{
        position: relative;
        height: 60px;
        padding-bottom: 8px;
        background-color: #e6e6e6;
    }

    .type-col-total{
        .holder-base-canvas{
            background-color: transparent;
            padding-bottom: 0px;
        }
    }
    
}


@media only screen and (min-width: 950px) {
    .zone-content:not(.general-comment) textarea {
        min-height: 180px !important;
    }
}