@page {
  size: A4 landscape;
  margin: 0;
}

// plan pdf
#hijacked-warning {
    display: none;
}

.blue-text {
  color: #6abdcf;
}

.nopadding {
  padding: 0;
}
.norightpadding {
  padding-right: 0;
}

.light-blue-text {
  color: #85d8ea;
}

.dark-blue-text {
  color: #324f6f;
}
html, body {
  height: 100%;
}
.plan-pdf {
  h1 {
      font-size: 1.7em;
      margin-top: 0.8em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.4em;
      margin-top: 0.5em;
    }
    h3 {
      font-size: 1.2em;
      &.text-right {
        margin-top: 3.3em;
      }
    }
  .row {
    margin: 0;
  }
  header {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  footer {
    display: none;
  }
  header {
    .logo {
      margin-top: 0.5em;
      border-right: 1px solid white;
    }
  }
  #table-header {
      margin-bottom: 0;
      padding-bottom: 0;
  }
  section {
    margin-bottom: 1.5em;
    .page {
      clear: both;
    }
  }
  table {
    width: 100%;
    margin-bottom: 1em;
    th {
      color: #7889a7;
      //background: #f3f7fa;
      background: #fff;
      line-height: 3em;
      text-transform: uppercase;
    }
    thead {
      tr {
      /*border-top: none;*/
      boorder-bottom: none;
      }
    }
    tr {
      border-top: 1px solid #8e9eb8;
      border-bottom: 1px solid #d4dde6;
      border-left: 1px solid #d7dae3;
      border-color:#3250b8;
      .tool-col,
      .product-col {
        width: 16%;
      }
      td.product-col .product {
        display: inline-block;
        min-height: 14.3em;
        width: 100%;
      }
      .equipment-col,
      .dilution-col{
        width: 8%;
      }
      .equipment-col img{
        max-width: 3.5em;
      }
      .procedure-col {
        width: 20%;
      }
      .frequency-col {
        width: 12%;
      }
      .safety-col {
        width: 18%;
        .stepicon {
          width: 50px;
        }
      }
      td, th {
        border-right: 1px solid #d7dae3;
        border-color:#3250b8;
        text-align: center;
        padding: 0em 0.5em;
      }
      &.header {
        border: none;
        th {
          border: none;
        }
      }
      td {
        vertical-align: top;
        padding: 8px 5px;
        overflow: hidden!important;
        .product-image {
          max-width: 10em;
          max-height: 150px;
          margin: 0 auto 0.5em;
          padding:0;
          .img-responsive {
            margin: 0 auto;
          }
        }
        p {
          margin: 0;
        }
        .col-xs-6 {
          padding: 0 7px;
        }
        &.safety-col,
        &.procedure-col {
          text-align: left;
          div {
            margin: 0 -0.5em 0.5em;
            padding: 0.5em 1em;
            border-bottom: 1px solid #d7dae3;
            border-color:#3250b8;
            min-height: 4.4em;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }
          }
          table {
            border: 0;
            margin: 0;
            tr, td {border: 0}
            td {
              padding: 0.2em;
              text-align: left;
              vertical-align: middle;
              &.position {
                width: 1em;
              }
              &.step-icon {
                width: 2em;
              }
            }
          }
          img {
            display: inline-block;
            max-width: 3.5em;
          }
        }
        &.dilution-col p,
        &.frequency-col p {
          margin-top: 0.8em;
          text-align: center;
        }
      }
    }
  }
  .task-img {
    height: 70px;
    width: auto;
    margin : auto;
  }
 .planarea,
  .plantask {
    padding-left: 2px;
    padding-right: 2px;
    p {line-height: 1.2em;}
  }
  footer.row {
    margin-top: 2em;
    padding: 1.5em 2em;
    .logo {
      max-width: 8em;
      margin-right: 1em;
    }
  }
}

header.pdf {
  overflow: auto;
  padding-top: 1em;
  margin-bottom: 1em;
  .logo {
    padding-left: 2.8em;
  }
  h1 {
    font-size: 1.7em;
    margin-top: 0.5em;
    font-weight: bold;
  }
  h2 {
    font-size: 1.5em;
    margin-top: 0.5em;
  }
  h3 {
    margin-right: 1.8em;
  }
}
.block {
  display: block;
  clear: both;
  width: 100%;
  margin-bottom: 1em;
}
#tasks-table {
  max-width: 98%;
  margin: 1em auto;
  overflow: auto;
  clear: both;
}

#tasks-table tbody {
  page-break-inside: auto!important;
}
header {
  page-break-after: avoid!important;
}
section {
  page-break-before: avoid!important;
  page-break-inside: avoid;
}
.breakafter {
  page-break-after: always!important;
}
#tasks-table tbody tr {
  page-break-inside: avoid !important;
  page-break-after: avoid!important;
  height: 100px!important;
  &.breakafter {
    page-break-after: always!important;
    //td {
    //  background-color: yellow;
    //}
  }
}

//table#tasks-table tr#info-top-table {
//  &.startpage-1 th {
//    background-color: green;
//  }
//}

#tasks-table tbody  {
  page-break-inside: auto!important;
}
#tasks-table tr.header.hideheader {
  height: 50px;
  th {
    background: white;
  }
  div {
    display: none;
  }
}

#tasks-table tbody tr td {
  height: 80px!important;
  min-height: 80px!important;
  
  > * {
    display: inline-block!important;
  }
  &.safety {
    padding: 10px 0;
    div {
      padding-right: 0;
    }
  }
}
#tasks-table .img-responsive {
  display: inline-block;
}
.plan-pdf footer.pdf {
  background: transparent;
  span {
    margin-right: 1em;
  }
}

#tasks-table table,
div.page {
    page-break-inside: avoid !important;
}

.pdf {
  .col-xs-1 {
    width: 2%;
  }
  .col-xs-10 {
    width: 96%;
  }
}

#header-page-table {
    margin: 0;
    tr, td, th {
        border: none;
        background: transparent;
    }
}

.head-info-text {
    
    color: #000;
    text-transform: uppercase;
    //font-size: 20px;
    font-size: 17px;
    padding-top: 0;
    margin-top: 0;
    div{
      margin: 0;
      padding: 0;
      height: 17px;
      margin-top:0;
      &:last-child{
        margin-bottom: 15px;
      }
      margin-bottom: 5px;
    }
    span {
        color: #757575;
        text-transform: capitalize;
        line-height: 17px;
        
    }
    .titlePlanHigiene{
      color:#0b34b2;
    }
    .titleCenter{
      color:#0b34b2;
    }
    .titleFacility{
      color:#0b34b2;
    }
    .valueCenter{
      color:#0b34b2;
    }
    .valueFacility{
      color:#0b34b2;
    }
}

#info-top-table {
    /*border: none;*/
    min-height: 40px!important;
    height: 40px !important;
    max-height: 40px!important;
    th {
        min-height: 40px!important;
        height: 40px !important;
        max-height: 40px!important;
        background: #0b34b2;
        color: #FFF;
        text-align: left;
        vertical-align: middle!important;
        h2{
            color: #FFF;
            text-align: left;
            padding: 0 10px;
            font-size: 20px;
        }
    }
}


#head-details-table th {
    background: #3151b8;
    color: #FFF;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    font-weight: normal;
}
.cap-txt {
    text-transform: capitalize;
}
.upper-txt{
  text-transform: uppercase;
}
.lower-txt {
    text-transform: lowercase;
}
#table-header {
    margin-bottom: 0;
    padding-bottom: 0;
}
#tasks-table  {
    margin-top: 0;
    vertical-align: top;
    /*tbody tr:nth-child(odd) td{
        background: #d1eef4;
    }*/
}

.inf-b-txt {
    display: block;
    color: #222;
    font-size: 12px;
    line-height: 1.3em;
}
.txt-a-center {
    text-align: center;
}
.txt-a-left {
    text-align: left;
}
.txt-a-right {
  text-align: right;
}
.txt-uppercase{
  text-transform: uppercase;
}
.safetysymbol {
  display: inline-block;
  min-height: 80px;
}
.xs-img {
    width: 40px;
    height: auto;
    margin: 0 auto;
}
.sm-img {
    width: 90px;
    height: auto;
    margin: 0 auto;
}
.va-middle {
    vertical-align: middle !important;
  .inf-b-txt {
    font-size: 14px;
  }
}
.img-dosif {
    margin-right: 10px;
    width: 50px;
    height: auto;
}
 .d-table {
    display: table;
}
.dt-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    float:none;
}
.mb-10 {
    margin-bottom: 10px;
}
.mx-w-75 {
    max-width: 75px;
}
.wbreak {    
    word-wrap: break-word;
    hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}
.np-left {
    padding-left: 0;
}
.np-right {
    padding-right: 0;
}
.np-x  {
    padding-left: 0;
    padding-right: 0;
}

.img-left-logo{
    padding: 0.5em 1em;
}

.img-right-logo{
    width: 50%;
    float: left;
}

.img_right_padding{
    padding: 0.7em 0em;
}
// RECOMENDACIONES GENERALES FIXED FOOTER
#recomendacionesGenerales{
  .titleRecomendations{
    h1{
      color: #fff;
      margin: 0;
    }
  }
  table{
    background-color: #0b34b2;
    margin: 0;
    tr, td {
      border: 0;
    }
    td{
      padding: 10px;
      vertical-align: middle;
    }
    table {
      background-color: #fff;
      td{
        padding: 0;
        margin:0;
        vertical-align: middle;
      }
    }
  }
  .titleInsti{
    color:#0b34b2;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -2px;
    margin-right: -10px;
  }
}

.labelTurno{
  padding-bottom: 5px;
  border-bottom: 2px solid #314360;

}
// --- COLORES POR INSTANCIA
// COLORES PARA GLOBAL
.GLOBAL{
  &.plan-pdf{
    
    table {
        th {
          color: #7889a7;
          background: #fff;
        }
        tr {
          border-top: 1px solid #8e9eb8;
          border-bottom: 1px solid #d4dde6;
          border-left: 1px solid #d7dae3;
          border-color:#3151B8;
          &.header {
            border: none;
            th {
              border: none;
            }
          }
          td, th {
            border-right: 1px solid #d7dae3;
            border-color:#3151B8;
          }
          td {
            &.safety-col,
            &.procedure-col {
              div {
                border-bottom: 1px solid #d7dae3;
                border-color:#3151B8;
              }
            }
          }
        }
        
      }
    }
  .labelTurno{
  border-color: #3151B8;
  }
  #recomendacionesGenerales{
    table{
      background-color: #3151B8;
    }
    .titleInsti{
      color:#0B34B2;
    }    
  }
  .head-info-text {
    border-top: 0;
    .titlePlanHigiene{
      color:#757575;
    }
    .titleCenter{
      color:#757575;
    }
    .titleFacility{
      color:#757575;
    }
    .valueCenter{
      color:#000429;
    }
    .valueFacility{
      color:#000429;
    }
  }
  
  #info-top-table {
    border-top: 0;
    border-left: 0;
    th {
        background: #0B34B2;
        border-top: 0;
        &.bg-primary {
          background: #3151B8;
        }
    }
  }

  #head-details-table th {
    background: #3151B8;
  }

  
}
// COLORES PARA DICAS
.DICAS{
  &.plan-pdf{
    
    table {
        th {
          color: #7889a7;
          background: #fff;
        }
        tr {
          border-top: 1px solid #8e9eb8;
          border-bottom: 1px solid #d4dde6;
          border-left: 1px solid #d7dae3;
          border-color:#314360;
          &.header {
            border: none;
            th {
              border: none;
            }
          }
          td, th {
            border-right: 1px solid #d7dae3;
            border-color:#314360;
          }
          td {
            &.safety-col,
            &.procedure-col {
              div {
                border-bottom: 1px solid #d7dae3;
                border-color:#314360;
              }
            }
          }
        }
        
      }
    }
  .labelTurno{
  border-color: #314360;
  }
  #recomendacionesGenerales{
    table{
      background-color: #314360;
    }
    .titleInsti{
      color:#8A9ECA;
    }    
  }
  .head-info-text {
    border-top: 0;
    .titlePlanHigiene{
      color:#8A9ECA;
    }
    .titleCenter{
      color:#8A9ECA;
    }
    .titleFacility{
      color:#8A9ECA;
    }
    .valueCenter{
      color:#314360;
    }
    .valueFacility{
      color:#314360;
    }
  }
  
  #info-top-table {
    border-top: 0;
    border-left: 0;
    th {
        background: #8A9ECA;
        border-top: 0;
        &.bg-primary {
          background: #314360;
        }
    }
  }

  #head-details-table th {
    background: #314360;
  }
  .sp-button-prisma, .sp-button-default{
    background-color: #314360;
    &:hover{
      background-color:#8A9ECA;
    }
  }
  
}