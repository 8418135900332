
// Close
.close {
	color: $brand-primary;
	opacity: 1.0;
	
	&:hover,
	&:focus {
		color: $brand-primary-hover;
		opacity: 1.0;
	}
	&:active {
		color: $brand-primary-active;
		opacity: 1.0;
	}
}