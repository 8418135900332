
// rutas
$pathImg: '../img';


// Colors
$white: #ffffff;
$black: #000000;
$border-grey: #e6eaed;
$border-grey-blue: #95a6b8;
$blue: #36aeea;

// Forms

$form-block-border-style: 1px solid $table-border-color;


// Sidebar

$sidebar-width: 250px;