.contact-body {
  padding-top: 2.2em;
  &:after,
  &:before{
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 38em;
    top: 0;
    z-index: 1;
    background: #6488ba;
    background: -moz-linear-gradient(-45deg, red, blue);
    background: linear-gradient(-31deg, #597daf 49%, #6488ba 49%);
  }
  &:after {
    right: 0;
    background: #597daf;
    background: linear-gradient(28deg, #597daf 70%, #6488ba 70%);
  }
  > * {
      z-index: 2;
      position: relative;
  }
  h1 {
    font-weight: bold;
    color: #00d2e9;
    margin: 0.4em 0 1.8em;
    b {
      color: #8edcdc;
    }
  }
  .contact-form {
    margin: 0 auto;
    float: none;
    padding: 4em;
    border: 1px solid #eaebef;
    border-radius: 0.2em;
    box-shadow: 0px 0px 3px #eaebef;
    background: white;
    h2 {
      font-family: 'Poppins', sans-serif;
      font-size: 2.5em;
      font-weight: bold;
      margin: 1.6em 0;
      border-bottom: 0.1em solid #ecedef;
    }
    .subtitle {
      font-size: 1.2em;
      line-height: 1.8em;
    }
    form{
      label {
        color: $text-color;
        display: block;
        font-weight: bold;
      }
      input[type="text"],
      input[type="email"],
      textarea {
        width: 100%;
        padding: 0.5em;
        border: 1px solid $gray-lighter;
        border-radius: 0.2em;
        &:focus {
          border-color: $brand-primary-alt-bright;
        }
      }
      input[type="text"],
      input[type="email"] {
        line-height: 2.4em;
      }
    }
    .buttons {
      clear: both;
      margin-top: 2em;
      padding-top: 2em;
      border-top: 0.1em solid #ecedef;
    }
    .btn-big {
      color: white;
      background-color: $brand-primary-alt-bright;
      width: 40%;
      min-width: 14em;
      font-size: 1.4em;
      font-weight: bold;
      height: 4em;
      text-transform: uppercase;
      &:hover {
        background-color: $brand-primary-alt-dark;
      }
    }

  }
}
.contact-footer {
  padding: 2em;
  background-color: $gray-lightest;
}

@media (max-width: $screen-sm-max) {
  .contact-body {
    &:before {
      background: linear-gradient(-31deg, #597daf 71%, #6488ba 71%);
    }
    &:after {
      background: linear-gradient(28deg, #597daf 76%, #6488ba 76%);
    }
  }
}
@media (max-width: $screen-xs-max) {
  .contact-body {
    padding-top: 1.2em;
    &:before {
      background: linear-gradient(-31deg, #597daf 84%, #6488ba 84%);
    }
    &:after {
      background: #597daf 86%;
    }
    .logo img {
      height: 7em;
    }
    h1 {
      font-size: 1.4em;
    }
    .contact-form {
      padding: 2.9em;
      h2 {
        font-size: 2.2em;
        padding-bottom: 0.6em;
        margin: 0.9em 0 0.6em;
      }
      p.subtitle {
        margin: 0;
      }
      .voffset-3 {
        margin-top: 2.7em;
      }
    }
  }
  @media (max-width: $screen-xs-min) {
    .logo img {
      height: 5em;
    }
    .contact-form {
      padding: 1em;
      .voffset-3 {
        margin-top: 2em;
      }
    }
  }
}