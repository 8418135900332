// Forms

@import "cleanpro/mixins";

.form-control {
	height: 40px;
	border-radius: 5px;
	position: relative;
}

select,
select.form-control {
	padding-right: 2em;
	-webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
	position: relative;
	background: transparent;
	z-index: 2;
	cursor: pointer;
}
select.form-control[disabled] {
	z-index: 0;
}

.select-icon {
    position: absolute;
    margin: 0;
	right: 1.5em;
	right: 1.5em;
	bottom: 1.3em;
    color: $brand-primary-alt-bright;
    font-weight: bold;
    font-size: 1.4em;
}

.dilution {
	position: relative;
	.select-icon {
		position: absolute;
		right: 0.7em;
		top: 0.6em;
		margin: 0;
	}
}
select.form-control[disabled] + .select-icon {
	color: white;
}

.statusFilter {
	margin-right: 2em;
	position: relative;
	display: inline-block;
	.select-icon {
		right: 0.5em;
		bottom: 0.5em;
	}
}

input[type=text],
input[type=number] {
	color: $brand-primary-alt-bright;
}

label,
.form-label {
	@include form-label-like;
	margin-right: 6px;
}

.help-block {
	color: #5b7984;
}


// Validation
// must be toggled visible in a wrapper
.validating {
	input.invalid,
	input[class*=ng-invalid],
	select.invalid,
	select[class*=ng-invalid] {
		@include invalid-field;
	}
}


// BS form-horizontal extras
.form-horizontal {
	
	// Data display without form widget
	.data-value {
		display: inline-block;
		margin-bottom: $line-height-computed;
		// Match label padding for vertical centering
		@media (min-width: $screen-sm-min) {
			padding-top: ($padding-base-vertical + 1);
		}
	}
}


label.status {padding-right: $grid-gutter-half;}

// TODO: deprecate in favour of BS form-horizontal
// Cleanpro form layout
// vertically centered, full width, padded
// Auto-cell width, no BS grid
.form-inline-block {
	margin-bottom: 20px;
	
	@media (min-width: $screen-sm-min) {
		@include table-like;
		// Cells
		& > * > * {
			vertical-align: middle;
		}
		// no wrap mode
		&.form-inline-block-nowrap > * > * {
			@include nowrap;
		}
		
		// Label cells
		.form-inline-block-label {
			width: 1px;
			padding-right: $grid-gutter-half;
		}
	
		// Field cells
		.form-inline-block-field {
			width: 100%;
			.select-icon {
				bottom: 1.6em;
			}
		}
	}
	
	// Field cells
	.form-inline-block-field {
		width: 100%;
	}

}

.ajax-form-status {
	font-weight: bold;
	font-size: 1.2em;
}

.select-wrapper {
	background: white;
	border-radius: 5px;
	select  {
		margin: 0;
	}
	select.form-control[disabled] {
		background-color: transparent;
	}
	&[disabled] {
		background: $brand-primary-subtle-dark;;
	}
}

i[disabled] {
	cursor: not-allowed;
}
