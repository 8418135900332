
.icon {
	@include fa;
	font-size: 24px;
	&::before {
		content: "\f0a9"; // fa-arrow-circle-right
		// baseline shift
		position: relative;
		top: 2px;
	}
	
	&.icon-button:hover {
		cursor: pointer;
	}
	
	// Variants
	&.ok::before {
		color: $brand-success;
		content: "\f058"; // fa-check-circle
	}
	&.error::before {
		color: $brand-danger;
		content: "\f071"; // fa-exclamation-triangle
	}
	&.add::before {
		content: "\f055"; // fa-plus-circle
	}
	&.bars::before {
		content: "\f0c9"; // fa-bars
	}
	&.close::before {
		content: "\f057"; // fa-times-circle
	}
	&.configure::before {
		content: "\f013"; // fa-cog
	}
	&.search::before {
		content: "\f002"; // fa-search
	}
	&.delete::before {
		color: $brand-danger;
		content: "\f1f8"; // fa-trash
	}
	&.delete-white::before {
	    color: #ffffff;
		content: "\f1f8"; // fa-trash
	}
	
}