// Utils

@import "cleanpro/mixins";


.align-right {
	text-align: right;
}

.unselectable {
	@include unselectable;
}

.nowrap {
	@include nowrap;
}

.voffset-1 {
	margin-top: 1em;
}

.voffset-2 {
	margin-top: 2em;
}

.voffset-3 {
	margin-top: 3em;
}