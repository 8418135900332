.body-signup-register {
    font-family: Arial, Verdana, sans-serif;
    font-size: 16px;
    color: #494f58;
    background: #f4f5f5;
}

.title-signup-register {
	background: #084168; 
	border-radius: 10px 10px 0px 0px; 

	h1 {
		font-size: 30px; 
		margin: 1em 50px 1em; 
		text-align: left; 
		color: #ffffff;
	}
}

.margin-logo-signup {
	margin-top: 10px;
	margin-bottom: 20px;
}

.content-signup-register {
	background:#ffffff;
	border-radius: 0px 0px 10px 10px;
}

.div-button-signup{
	text-align: center; 
	margin-bottom: 50px; 
	margin-top: 25px;

	button{
		border: 0; 
		border-radius: 12px; 
		color: #FFF; 
		font-family: Arial, Verdana, 
		sans-serif; font-size: 13px; 
		padding: 10px 15px; 
		min-width: 200px; 
		display: inline-block; 
		text-decoration: none;
	}
}

.form-signup{
	label{
	    text-transform: capitalize;
	    color: #7e7b7b;
	    font-weight: initial;
	}
}

.sp-button-default{
	background: #31D3E8;
}

.sp-button-inpacs{
	background: #79A1D2;
}

.sp-button-adisco{
	background: #2082C3;
}

.sp-button-prisma{
	background: #60A8F4;
}

.sp-title-inpacs{
	background: #04315A !important;
}

.sp-title-adisco{
	background: #084168 !important;
}

.sp-title-prisma{
	background: #084168 !important;
}



