// Modal page

@import "bootstrap/mixins/center-block";
@import "cleanpro/mixins";


$modal-sleeve-brand-height: 100px;


body.page-modal {
	// Use full background when page is modal
	background: #f2f7fb;
	div.body {
		overflow: auto;
	}
	header {
		padding-bottom: 0.7em;
		border-bottom: 1px solid $text-color-alt;
	}

	.page-modal-panel {
		margin-bottom: 3em;
	}

}

body.DICAS {
	.page-modal-panel{
		&::before{
			@include logo-dicas;
			background-size: 190px auto;
			background-position: center 28px;
		}
	}
}


.page-modal-panel {
	@include center-block;
	background-color: white;
	border-radius: $border-radius-large;
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
	max-width: 420px;
	padding: 25px 50px;
	margin-top: $modal-sleeve-brand-height + 80px;
	position: relative;
	
	// Upper sleeve with logo
	&::before {
		@include logo-prisma;
		background-size: 190px auto;
		background-position: center 28px;
		
		content: " ";
		border-radius: $border-radius-large;
		height: $modal-sleeve-brand-height + 20px;
		position: absolute;
		left: 0px;
		right: 0px;
		top: -$modal-sleeve-brand-height;
		z-index: -1;
	}
	.heading-app-name {
		display: inline-block;
		font-size: 0.9em;
		margin-top: 0.3em;
		padding-top: 0.3em;
		line-height: 1em;
	}
	.heading-app-tagline {
		float: left;
		display: block;
		font-size: 0.75em;
	}
	h1.heading {
		margin-top: 1.3em;
		letter-spacing: 0.1em;
	}
	.form-group {
		margin-top: 1.7em;
		label {
			letter-spacing: 0.15em;
		}
		input {
			height: 45px;
		}
	}
	a {
		letter-spacing: 0.05em;
	}
	.btn {
		margin-top: 0.8em;
		font-weight: bold;
		letter-spacing: 0.05em;
		padding: 13px 64px;
	}
}

.page-modal-panel.pmp-fch {
    header {
        border: none;
    }
	&::before{
	    display: none;
	}
	.heading.h-form {
	    color: $brand-primary-alt-darker;
	}
    .form-group {
        position: relative;
        input {
            background: none;
            border: none;
            color: #555555;
            font-size: 16px;
            font-weight: bold;
            padding-left: 50px;
            text-align: right;
            position: relative;
            z-index: 5;
            box-shadow: none;
            border: none;
            outline: none;
            border-bottom: 2px solid #25c7d2;
            border-radius: 0;
            &:focus {
                box-shadow: none;
                border: none;
                outline: none;
                border-bottom: 2px solid #25c7d2;
                }
            }
        label {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            height: 45px;
            padding: 12px 5px 12px 0;
            font-size: 15px;
            line-height: 1.42857;
            text-transform: uppercase;
            font-weight: 400;
       }
    }
    .btn {
        border-radius: 18px;
        background: #00b0ec;
    }
}