
.pagination {
	font-family: "Montserrat", "Open Sans", Helvetica, Arial, sans-serif;
	font-size: 13px;
	display: inline-block;
	padding-left: 0;
	
	& > li {
		display: inline;
		
		& > a,
		& > span {
			position: relative;
			float: left; // Collapse white-space
			padding: $padding-base-vertical $padding-base-horizontal;
			border-radius: 5px;
			line-height: $line-height-base;
			text-decoration: none;
			color: $brand-primary;
			background-color: white;
			
			&:hover,
			&:focus {
				z-index: 2;
				//color: $pagination-hover-color;
				background-color: $gray-lighter;
			}
		}
		
		&.active > a,
		&.active > span {
			&,
			&:hover,
			&:focus {
				z-index: 3;
				color: black;
				background-color: white;
				cursor: default;
			}
		}
		
		&.pagination-first,
		&.pagination-prev,
		&.pagination-next,
		&.pagination-last {
			& > a,
			& > span {
				// Content expected to be FontAwesome icons as unicode
				@include fa;
				font-size: 13px;
				line-height: $line-height-base;
				
				color: white;
				background-color: $brand-primary;
				padding: $padding-base-vertical 10px;
				margin-left: 2px;
				margin-right: 2px;
			}
			
			&.disabled > a,
			&.disabled > span {
				&,
				&:hover,
				&:focus {
					background-color: $gray-light-alt;
					cursor: normal;
				}
			}
		}
	}
}
