

// BS Panel with metrics
.panel.panel-metrics {
	border-radius: 0;
	margin-top: 1em;
	margin-bottom: 1em;
	
	.panel-heading {
		background-color: white;
	}
	
	.panel-title {
		font-size: 20px;
		color: #838c9b;
	}

	.panel-body{
		.big-box-info{
			.info-title{
				color: #333f57;
				font-size: 16px;
			}
		}
		
		.small-box-info{
			.info-title{
				color: #7088a2;
				font-size: 14px;
			}
		}
	}
}