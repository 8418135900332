.quality-control-list{

	.btn-edit, .btn-send, .btn-delete{
		text-align:center;
		min-width: 80px;
	}

	.btn-delete{
	    margin-top: 0.5em;
	}

	.tools-cn{
		.search-cn{

			.form-label{
				display: inline-block;
    			vertical-align: middle;
			}

			.form-input{
				display: inline-block;
			    max-width: 400px;
			    vertical-align: middle;
			    margin-left: 10px;
			    margin-bottom: 5px;
			}
		}
	}

	.type-analysis{

		.header{
			.date{
				width: 150px;
			}

			.name{

			}

			.records{
				width: 150px;
			}

			.analysis{
				width: 220px;
			}
		}
	}

	&.type-adisco{
		margin-bottom: 60px;

		.header.footer{
			border-bottom:1px solid #e5e5e5;
		}

		#form-search{
			.form-group{
				position: relative;
    			display: inline-block;

    			i{
    				top: 0;
				    bottom: 0;
				    right: 10px;
				    width: 18px;
				    height: 18px;
				    margin: auto;
				    color: $blue;
    			}
			}

			.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
				background-color: $white;
			}
		}

		.search-cn{
			.col-md-3{
				padding-right: 0;
			}
		}
		.text-search-label{
			border: transparent;
			background: transparent;
		}

		.text-select-box{
			position: relative;
			display: inline-block;
			// min-width: 250px;

			select{
				// max-width: 200px;
			}

			.select-icon {
				top:0;
				bottom: 0;
				right: 10px;
				margin: auto;
				height: 15px;
			}
		}

		.entry{
			// border-bottom: 1px solid #fff;
			transition: all (0.3s) ease;
			&:hover{
				background-color:  darken(#f3f7f9, 5%);
				//border-bottom-color: darken(#f3f7f9, 20%);
			}
		}

		.entries-table > * > *{
			vertical-align: middle;
		}

		.col-client{
			border-left: 1px solid #e5e5e5;
		}

		.col-client, .col-total{
			position: relative;
			border-right: 2px solid #e5e5e5;
		}

		.order-cn{
			position:absolute;
			top: 0;
			bottom:0;
			right:6px;
			margin:auto 0;
			height: 26px;

			a{
				position: relative;
				display:block;
				width: 8px;
				height: 8px;
				margin: 4px 0;
				font-size: 18px;
				color: #fff;
				@include transition();

				i{
					position: absolute;
					top: -5px;
					left: 0;
				}

				&.active{
					color: #36aeea;
				}
			}
		}

		.col-center{
			border-left:2px solid #e5e5e5;
			border-right:1px solid #e5e5e5;
		}

		.head-date{
			padding: 10px;
			p{
				display:inline-block;
				vertical-align: middle;
				width: 80%;

				span{
					display: block;
				}
			}
		}

		.nav-btn{
			width: 20%;
			margin-right: -5px;
			display:inline-block;
			vertical-align: middle;

			&.type-left{
				transform: translateX(-6px);
			}

			&.type-right{
				transform: translateX(6px);
			}
		}

		.col-empty{
			background: #fff;
		}

		.col-date{
			.mark-cn{
				font-size: 14px;
				color: #7c7a7c;

				&.value-max{
					background-color: #249bf2;
					background-color: rgba(#249bf2, .4);
				}

				&.value-medium{
					background-color: #fac737;
					background-color: rgba(#fac737, .5);
				}

				&.value-min{
					background-color: #f35437;
					background-color: rgba(#f35437, .5);
				}

				&.value-nr{
					background-color: #c3c3c3;
					background-color: rgba(#c3c3c3, .5);
				}
			}
		}

		.mark-cn{
			display: inline-block;
			min-width: 70px;
			text-align: center;
			padding: 6px 12px;
			border-radius: 25px;
			//background-color: #f35437;
			color: $white;
			font-size: 16px;

			&.value-max{
				background-color: #249bf2;
			}

			&.value-medium{
				background-color: #fac737;
			}

			&.value-min{
				background-color: #f35437;
			}

			&.value-nr{
				background-color: #c3c3c3;
			}

		}

		.col-supervisor, .col-records{
			position: relative;

			.order-cn {
				right: 10px;
			}
		}

		.col-alert{

			a{
				display:inline-block;
				width: 26px;
				height: 26px;
				line-height: 26px;
				color: #353D48;
				background-color: #FACA90;
				@include roundAll100(50%);
				@include transition();

				&:hover, &:focus{
					background-color: darken(#FACA90, 10%);
					text-decoration: none;
					transform: scale(1.2);
				}
			}
		}
	}
}
.text-search-box{
    position: relative;
    display: inline-block;

        .text-search-label {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 0;
            text-indent: -200%;
            color: transparent;
            &:before {
                position: absolute;
                right: 0;
                top: 0;
                width: 38px;
                height: 38px;
                color: #36aeea;
                content: "\f002";
                font: normal normal normal 13px/1 FontAwesome;
                font-size: 18px;
                line-height: 100%;
                vertical-align: middle;
                padding: 10px 10px;
                z-index: 0;
                cursor: pointer;
                text-indent: 0;
            }

        }
}
.text-search {
	display: block;
	background: white;
	border: 1px solid #c6cad3;
	border-radius: $border-radius-small + 1;
	padding: 10px 12px 9px;
}