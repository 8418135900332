

// Block with superbrand logo
@mixin logo-prisma {
	background-color: $brand-primary-alt-darker;
	background-image: url("img/logo_prisma.png");
	background-repeat: no-repeat;
	background-position: center; //defaults
	background-size: 100% auto; //defaults
	
}

@mixin logo-dicas {
	background-color: #324061;
	background-image: url("img/logo_dicas.png");
	background-repeat: no-repeat;
	background-position: center; //defaults
	background-size: 100% auto; //defaults
	
}


// Montserrat
@mixin montserrat {
	font-family: $font-family-montserrat;
}


// Uppercase
@mixin uppercase {
	text-transform: uppercase;
}


// Section
@mixin section-like {
	margin-top: 1em;
	margin-bottom: 1em;
}

// Table-like
@mixin table-like {
	display: table;
	border-collapse: collapse;
	width: 100%;
	// Rows
	& > * {
		display: table-row;
		// Cells
		& > * {
			display: table-cell;
		}
	}
	
	&.reset-widths {
		& > * > * {
			// Exact column widths are set later
			max-width: 0;
		}
	}
}


// Form label-like
@mixin form-label-like {
	color: $brand-primary-alt-darker;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 5px;
}


// FontAwesome
@mixin fa {
	display: inline-block;
	font: normal normal normal 13px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


// Disable text selection
@mixin unselectable {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

// No wrap
@mixin nowrap {
	white-space: nowrap;
}

@mixin invalid-field {
	border: 2px solid $brand-danger;
}

@mixin box-shadow($direction:bottom, $size:5px, $color:#333 ) {

	@if ($direction == "top") {
		-moz-box-shadow: 0 (-$size) $size (-$size) $color;
		-webkit-box-shadow: 0 (-$size) $size (-$size) $color;
		box-shadow: 0 (-$size) $size (-$size) $color;

	} @else if ($direction == "right"){
		-moz-box-shadow: $size 0 $size (-$size) $color;
		-webkit-box-shadow: $size 0 $size (-$size) $color;
		box-shadow: $size 0 $size (-$size) $color;

	} @else if ($direction == "bottom"){
		-moz-box-shadow: 0 $size $size  (-$size) $color;
		-webkit-box-shadow: 0 $size $size  (-$size) $color;
		box-shadow: 0 $size $size (-$size) $color;

	} @else if ($direction == "left"){
		-moz-box-shadow: (-$size) 0 $size (-$size) $color;
		-webkit-box-shadow: (-$size) 0 $size (-$size) $color;
		box-shadow: (-$size) 0 $size (-$size) $color;

	} @else if ($direction == "all"){
		-moz-box-shadow: 0 0 $size $color;
		-webkit-box-shadow: 0 0 $size $color;
		box-shadow: 0 0 $size $color;
	}

}

@mixin roundAll($val:5){
	border-radius: $val + px;
	-ms-border-radius: $val + px;
	-moz-border-radius: $val + px;
	-webkit-border-radius: $val + px;
	-khtml-border-radius: $val + px;
}

@mixin roundAll100($val:50%){
	border-radius: $val;
	-ms-border-radius: $val;
	-moz-border-radius: $val;
	-webkit-border-radius: $val;
	-khtml-border-radius: $val;
}

@mixin transition($val:0.3) {
	-webkit-transition: all ($val + s) ease;
    -moz-transition: all ($val + s) ease;
    -ms-transition: all ($val + s) ease;
    -o-transition: all ($val + s) ease;
    transition: all ($val + s) ease;
}

@mixin bgImg($img: null, $position: center, $size: cover, $repeat:no-repeat){
	@if ($img != null) {
		background-image: url('#{$pathImg}/#{$img}');
	}
	background-position: $position;
	background-size:$size;
	background-repeat: $repeat;

}